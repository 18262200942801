import React, { useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { getResponse } from "../../../../requests/getApi"
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ProductDialog from "../forms/orderComponents/ClassDialog"
import { classGet } from '../../../../utils/apiRequest'
import {
    Grid,
    TextField,
    Divider,
    Button,
    FormControlLabel,
    Checkbox,
    makeStyles,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { VALUES, DARKVALUES, GREENVALUES } from '../../../../constants/styleConstants';
import defProduct from "../../../../assets/images/defCategory.png";

//

//

export default function UsersForm(props) {
    const theme = useSelector((state) => state.theme);
    let colorValues;
    if (theme.name === 'dark') {
        colorValues = VALUES;
    } else if (theme.name === 'green') {
        colorValues = GREENVALUES;
    } else {
        colorValues = DARKVALUES;
    }

    const useStyles = makeStyles((theme) => ({
        imageBox: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        image: {
            height: '140px',
            width: '180px',
            marginBottom: '10px',
            border: '1px solid black',
            borderRadius: '5px',
            objectFit: "contain"
        },
        uploadBtn: {
            padding: '4px',
        },
        divider: {
            margin: '20px 0',
        },
        rightContainer: {
            display: 'flex',
            flexDirection: 'column',
        },

        //
        colorPrimary: {
            color: colorValues.colorPrimary,
            background: colorValues.colorBgPrimary,
        },
    }));

    const { input } = props;
    const classes = useStyles();
    const [isAddModalOpen, setAddModal] = useState(false);
    const [globalClass, setGlobalClass] = useState([]);
    const [parentIdApi, setParentIdApi] = useState({ error: '', success: '' })
    const [modalLoad, setModalLoad] = useState(false)
    const [parent, setParent] = useState(null)
    const [productCategories, setProductCategories] = useState([]);
    const { t } = useTranslation();
    console.log(input)
    React.useEffect(() => {
        if (parent !== null) {
            setModalLoad(true);//
            classGet(parent).then((res) => {
                const productCategories = res.data?.results;
                setProductCategories(productCategories);
                setGlobalClass(productCategories);
                console.log(res.data)
                setModalLoad(false)
            });
        }
    }, [parent])
    React.useEffect(() => {
        if (input?.parent && input?.parent !== 1) {
            getResponse(`/classification/${input?.parent}/`, setParentIdApi)
        }
    }, [input?.parent])
    const handleAddIconCLick = () => {
        setParent(1)
        setAddModal(true)
    }
    return (
        <>
            <Divider className={clsx(classes.divider, classes.colorPrimary)} />
            <Grid container justify="space-around">
                <Grid item sm={5}>
                    <TextField
                        fullWidth
                        required
                        variant="outlined"
                        id="name"
                        label={t('categoryProps.name')}
                        type="text"
                        value={props.input['name'] || ''}
                        onChange={props.handleTextChange('name')}
                        margin="dense"
                    />
                </Grid>
                <Grid item sm={5} className={classes.rightContainer}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        id="name_ru"
                        label={t('categoryProps.name_ru')}
                        type="text"
                        value={props.input['name_ru'] || ''}
                        onChange={props.handleTextChange('name_ru')}
                        margin="dense"
                    />
                    <TextField
                        fullWidth
                        variant="outlined"
                        id="name_uz"
                        label={t('categoryProps.name_uz')}
                        type="text"
                        value={props.input['name_uz'] || ''}
                        onChange={props.handleTextChange('name_uz')}
                        margin="dense"
                    />
                    <TextField
                        fullWidth
                        variant="outlined"
                        id="name_en"
                        label={t('categoryProps.name_en')}
                        type="text"
                        value={props.input['name_en'] || ''}
                        onChange={props.handleTextChange('name_en')}
                        margin="dense"
                    />
                </Grid>
            </Grid>
            <Divider className={clsx(classes.divider, classes.colorPrimary)} />

            <Grid container justify="space-around">
                <Grid item sm={3}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="is_active"
                                value={props.input['is_active'] || false}
                                color="primary"
                                onChange={props.handleTextChange('is_active')}
                            />
                        }
                        label={t('categoryProps.is_active')}
                    />
                </Grid>

                <Grid item sm={4}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        id="parent"
                        label={t('categoryProps.parent')}
                        type="text"
                        value={props.input['parentName'] || parentIdApi?.success?.data?.name || ''}
                        onChange={props.handleTextChange('parentName') || props.handleTextChange('parent')}
                        margin="dense"
                    />
                </Grid>
                <Grid item sm={1}>
                    <AddCircleIcon
                        onClick={handleAddIconCLick}
                        color="primary"
                        style={{ fontSize: 40, marginTop: 5, marginLeft: 8, cursor: 'pointer' }}
                    />
                </Grid>
                <Grid item sm={4}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        id="icon"
                        label={t('unit_typeProps.value_en')}
                        type="text"
                        value={props.input['value'] || ''}
                        onChange={props.handleTextChange('value')}
                        margin="dense"
                        required
                    />
                </Grid>
            </Grid>
            <ProductDialog
                open={isAddModalOpen}
                setOpen={setAddModal}
                global={globalClass}
                setParent={setParent}
                parent={parent}
                disableId={props.disableId}
                modalLoad={modalLoad}
                setModalLoad={setModalLoad}
                setIdParent={props?.setIdParent}
                productCategories={productCategories}
                setProductCategories={setProductCategories}
            />
        </>
    );
}
