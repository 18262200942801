import {
    USER_SET,
    USER_REMOVE,
} from '../actionTypes';

const defaultUser = {
    id: '',
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    username: '',
    role: '',
};

export default function tokenReducer(state = defaultUser, action) {
    switch (action.type) {
        case USER_SET:
            return action.payload;

        case USER_REMOVE:
            return { first_name: 'U' };

        default:
            return state;
    }
}
