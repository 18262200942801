import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    makeStyles,
} from '@material-ui/core';
import {VALUES, DARKVALUES} from '../constants/styleConstants';

//
const useStyles = makeStyles((theme) => ({
    btnConfirm: {
        background: '#32A852',
        color: '#FFF',
    },

    colorPrimary: {
        color: VALUES.colorPrimary,
        background: VALUES.colorBgPrimary,
    },
}));

//
/** props: open, header, text, handleClose, handleConfirm */
export default function DialogForm(props) {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div>
            <Dialog
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">{props.header} </DialogTitle>
                <DialogContent>
                    <DialogContentText>{props.text}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        className={classes.colorPrimary}
                        onClick={props.handleClose}
                    >
                        {t('confirm.cancel')}
                    </Button>
                    <Button
                        variant="contained"
                        className={classes.btnConfirm}
                        onClick={props.handleConfirm}
                    >
                        {t('confirm.confirm')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
