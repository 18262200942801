import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import ProductsForm from './forms/ProductsForm';
import Table from '../components/TableView';
import FormController from '../components/FormController';
import { productPropList as propList } from '../../../constants/propListConstants';
import getColumns from '../../../constants/tableColumnConstants';
import {
    productGet,
    // productGetById,
    productPost,
    productPut,
    productDelete,
} from '../../../utils/apiRequest';

export default function ProductsView(props) {
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [formOpen, setFormOpen] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [pagesToFetch, setPagesToFetch] = useState([]);
    const [waiting, setWaiting] = useState(false);
    const [filterId, setFilterId] = useState(null)
    const { t } = useTranslation();
    const columns = getColumns('product', t);

    useEffect(() => {
        setWaiting(true);

        const prms = [];
        for (let i of pagesToFetch) prms.push(productGet(i, filterId));

        Promise.all(prms)
            .then((res) => {
                const obj = [];
                res.forEach((el) => {
                    obj.push(...el.data.results);
                });

                setData([...data, ...obj]);
                setCount(res[0].data.count);
            })
            .catch((err) => {
                console.error(err);
                setData([]);
            })
            .finally(() => setWaiting(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagesToFetch, filterId]);

    useEffect(() => {
        setData([]);
        setPagesToFetch([1]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refresh]);

    React.useMemo(()=>{
        if(filterId) setData([])
    },[filterId])

    // Handlers
    //
    return (
        <>
            <Table
                rows={data}
                count={count}
                columns={columns}
                handleFormOpen={setFormOpen}
                waiting={waiting}
                refreshPage={() => {
                    setRefresh(!refresh);
                }}
                setPagesToFetch={setPagesToFetch}
                reqDelete={productDelete}
                productFilter
                setFilterId={setFilterId}
            />
            <FormController
                open={formOpen}
                data={data}
                propList={propList}
                header={t('propList.product')}
                render={(input, handleTextChange, handleFileChange) => (
                    <ProductsForm
                        input={input}
                        handleTextChange={handleTextChange}
                        handleFileChange={handleFileChange}
                    />
                )}
                handleClose={() => {
                    setFormOpen(false);
                }}
                refreshPage={() => {
                    setRefresh(!refresh);
                }}
                reqPost={productPost}
                reqPatch={productPut}
                reqDelete={productDelete}
            />
        </>
    );
}
