import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import {
    Container,
    FormControl,
    TextField,
    InputAdornment,
    IconButton,
    Button,
    CircularProgress,
    Paper,
    Box,
} from '@material-ui/core';
import { AccountCircle, Visibility, VisibilityOff } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

import * as cookies from '../../utils/Cookies';
import { login } from '../../utils/apiRequest';
import Alert from '../../components/Alert';
import { useSelector } from 'react-redux';
import {VALUES, DARKVALUES, GREENVALUES} from '../../constants/styleConstants';


function Login(props) {
    const theme = useSelector((state) => state.theme);
    let colorValues;
    if (theme.name === 'dark') {
        colorValues = VALUES;
    } else if (theme.name === 'green') {
        colorValues = GREENVALUES;
    } else {
        colorValues = DARKVALUES;
    }
    const useStyles = makeStyles((theme) => ({
        root: {
            maxWidth: '100vw !important',
            height: '100vh',
            padding: '0',
            margin: '0',
    
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        paper: {
            padding: '40px',
    
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        input: {
            marginBottom: '10px',
        },
        button: {
            width: 180,
            height: '40px',
            marginTop: '20px',
        },
    
        //
        colorPrimary: {
            color: colorValues.colorPrimary,
            background: colorValues.colorBgPrimary,
        },
        colorSecondary: {
            color: colorValues.colorSecondary,
            background: colorValues.colorBgSecondary,
        },
    }));
    const classes = useStyles();
    const [phone, setPhone] = useState('');//998911531419
    const [password, setPassword] = useState('');//hfjfdhjkH&9
    const [passHidden, setPassHidden] = useState(true);
    const [submitted, setSubmitted] = useState(false);
    const [alert, setAlert] = useState({ open: false, severity: '', message: '' });
    const { t } = useTranslation();

    function handleClickShowPassword() {
        setPassHidden(!passHidden);
    }
    function handleMouseDownPassword(e) {
        e.preventDefault();
    }
    function resetForm() {
        setPhone('');
        setPassword('');
        setSubmitted(false);
    }
    function handleSubmit(e) {
        e.preventDefault();
        setSubmitted(true);

        login({ phone, password })
            .then((res) => {
                if (res.status >= 300) {
                    setAlert({
                        open: true,
                        severity: 'error',
                        message: res.data.detail,
                    });
                    resetForm();
                } else if (res.status >= 200 && res.status < 300) {
                    cookies.authUser({ jwt: res.data.access, refreshToken: res.data.refresh });
                    setAlert({
                        open: true,
                        severity: 'success',
                        message: t('loginPage.alert_success'),
                    });
                    props.history.push('/');
                }
            })
            .catch((err) => {
                console.error(err);
                setAlert({
                    open: true,
                    severity: 'error',
                    message: t('loginPage.alert_error'),
                });
                resetForm();
            });
    }
    function handleAlertClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }

        setAlert({ ...alert, open: false });
    }

    return (
        <Container className={clsx(classes.root, classes.colorPrimary)}>
            <Paper elevation={3}>
                <Box className={classes.paper}>
                    <form onSubmit={handleSubmit}>
                        <FormControl variant="outlined">
                            <TextField
                                className={classes.input}
                                required
                                id="phone"
                                label={t('loginPage.phone')}
                                variant="outlined"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <AccountCircle
                                                className={classes.colorSecondary}
                                            />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <TextField
                                className={classes.input}
                                required
                                id="password"
                                label={t('loginPage.password')}
                                variant="outlined"
                                value={password}
                                type={passHidden ? 'password' : 'text'}
                                onChange={(e) => setPassword(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                className={classes.colorSecondary}
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {passHidden ? (
                                                    <VisibilityOff />
                                                ) : (
                                                    <Visibility />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />

                            <Button
                                className={clsx(classes.button, classes.colorPrimary)}
                                variant="contained"
                                type="submit"
                                size="large"
                                disabled={submitted}
                            >
                                {submitted ? (
                                    <CircularProgress
                                        size={28}
                                        style={{ color: 'white' }}
                                    />
                                ) : (
                                    t('loginPage.sign_in')
                                )}
                            </Button>
                        </FormControl>
                    </form>
                </Box>
            </Paper>

            <Alert
                open={alert.open}
                severity={alert.severity}
                message={alert.message}
                onClose={handleAlertClose}
            />
        </Container>
    );
}


export default withRouter(Login);