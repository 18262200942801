import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {Component} from "./style"
import {getResponse} from "../../../../requests/getApi"

const useStyles = makeStyles((theme) => ({
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

export default function ControlledOpenSelect({setPagesToFetch, setFilterId, onChange, onValue}) {
  const classes = useStyles();
  const [age, setAge] = React.useState('all');
  const [open, setOpen] = React.useState(false);
  const [state, setState] = useState({success:'',error:''})

  useEffect(()=>{ getResponse('/category/', setState) },[])

  const handleChange = (event) => {
    setAge(event.target.value);
    if(event.target.value === 'all'){
      setFilterId('')
    }else{
      setFilterId(event.target.value)
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <Component>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-controlled-open-select-label" variant="contained">Filter</InputLabel>
        <Select
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select"
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={onValue ? onValue : age}
          onChange={ onChange ? onChange : handleChange}
        >
          <MenuItem value="all">
            <em>Barchasi</em>
          </MenuItem>
          {
            state?.success?.data?.results?.map((item)=>(
              <MenuItem key={item?.id} value={item?.id}>{item?.name_uz}</MenuItem>
            ))
          }
        </Select>
      </FormControl>
    </Component>
  );
}
