import React, { useEffect, useState, useMemo } from 'react'
import TableOne from "./table.one"
import TableTwo from "./table.two"
import TableThree from "./table.three"
import { getApiResponseParse,getResponse } from "../../../../requests/getApi"
import ModalAdd from "./productmodal"
import "./style.css"

const Index = () => {
    const [firstTable, setFirstTable] = useState({ data: null, loading: false })
    const [tableThree, setTableThree] = useState([])
    const [firstClick, setFirstClick] = useState(null)
    const [stateGood, setStateGood] = useState([])
    const [stateGoodApi, setStateGoodApi] = useState([])
    const [pagingOne, setPagingOne] = useState({ page_size: 10, page: 1 })
    const [addModal, setAddModal] = useState(false)
    const [stateModal, setStateModal] = useState([])
    const [goodsCategory, setGoodsCategory] = useState({ success: '', error: '' })
    const [stateApiTable, setStateApiTable] = useState({ data: null, loading: false })
    const [page, setPage] = useState(0)
    const [tableList, setTableList] = useState([]);
    const [perPage, setPerPage] = useState(10)
    const [stateCenter, setStateCenter] = useState([])
    
    useEffect(() => { getApiResponseParse(`/products/?page=${page + 1}&page_size=${perPage}`, setFirstTable) }, [page, perPage])
    useEffect(() => {
        if (firstTable?.success && firstClick)
            getApiResponseParse(`/recipe/?page=${pagingOne?.page}&page_size=${pagingOne?.page_size}&product_id=${firstClick?.id}&is_active=true`, setStateApiTable)
    }, [firstTable, firstClick])
    useEffect(() => {
        if (addModal === true) {
            getResponse('/good-category/?page=1&page_size=20', setGoodsCategory)
        }
    }, [addModal])

    useEffect(() => {
        setStateGoodApi([])
        if (stateApiTable?.success) {
            if (stateApiTable?.data?.results?.length > 0) {
                setStateGoodApi(() => stateApiTable?.data?.results[0]?.items || [])
                let clone = stateApiTable?.data?.results[0]?.items?.map(prev => {
                    return {
                        ...prev,
                        good_id: prev?.good?.id,
                        quantity: parseInt(prev?.quantity)?.toFixed(),
                        calc_total: parseInt(prev?.quantity)?.toFixed() * parseInt(prev?.price)
                    }
                }
                )
                setTableThree(clone)
            }
            setStateGood([])
        }
    }, [stateApiTable, firstClick])
    useEffect(() => {
        setStateCenter([...stateGood, ...stateGoodApi])
    }, [stateGood, stateGoodApi])

    const handleChangePage = (e, newPage) => {
        setPage(newPage);
    }
    const handleChangePageRow = (e) => {
        setPerPage(parseInt(e.target.value, 10));
    }
    const handleDelete = (itemId) => {
        setStateCenter(item => item?.filter(prev => prev?.id !== itemId))
    }
    return (
        <div className="wrapper">
            <TableOne
                data={firstTable?.data}
                setFirstClick={setFirstClick}
                firstClick={firstClick}
                setPaging={setPagingOne}
                loading={firstTable?.loading}
                page={page}
                perPage={perPage}
                handleChangePage={handleChangePage}
                handleChangePageRow={handleChangePageRow}
            />
            <TableTwo
                stateModal={stateModal}
                addModal={addModal}
                data={stateCenter}
                setPaging={setPagingOne}
                setAddModal={setAddModal}
                firstClick={firstClick}
                setTableThree={setTableThree}
                tableThree={tableThree}
                loading={stateApiTable?.loading}
                handleDelete={handleDelete}
            />
            <TableThree
                data={tableThree}
                stateModal={stateModal}
                firstClick={firstClick}
            />
            <ModalAdd
                state={addModal}
                setState={setAddModal}
                setStateModal={setStateModal}
                stateModal={stateModal}
                goodsCategory={goodsCategory}
                setTableThree={setTableThree}
                open={addModal}
                stateCenter={stateCenter}
                setStateCenter={setStateCenter}
                setOpen={setAddModal}
                tableList={tableList}
                setTableList={setTableList}

            />
        </div>
    )
}

export default Index
