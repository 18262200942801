import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ProductsForm from './forms/moderationForm';
import Table from '../components/TableView';
import FormController from '../components/FormController';
import { productPropList as propList } from '../../../constants/propListConstants';
import getColumns from '../../../constants/tableColumnConstants';
import {
    modificationPost,
    modificationPut,
    moderationDelete,
    moderationGet,
} from '../../../utils/apiRequest';
import moment from 'moment';
//

export default function ProductsView(props) {
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [formOpen, setFormOpen] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [pagesToFetch, setPagesToFetch] = useState([]);
    const [editId, setEditId] = useState(null)
    const [waiting, setWaiting] = useState(false);
    const { t } = useTranslation();
    const columns = getColumns('modification', t);

    useEffect(() => {
        setWaiting(true);
        const prms = [];
        for (let i of pagesToFetch) prms.push(moderationGet(i));

        Promise.all(prms)
            .then((res) => {
                console.log(res);
                const obj = [];
                res.forEach((el) => {
                    obj.push(...el.data.results);
                });
                let date = obj.map((item) => {
                    return {
                        id:item.id,
                        name:item.name,
                        created_date:moment(item?.created_date).format("lll")
                    }
                })
                console.log(obj);
                setData([...data, ...date]);
                setCount(res[0].data.count);
            })
            .catch((err) => {
                console.error(err);
                setData([]);
            })
            .finally(() => setWaiting(false));
    }, [pagesToFetch]);
 
    useEffect(() => {
        setData([]);
        setPagesToFetch([1]);
    }, [refresh]);
    return (
        <>
            <Table
                rows={data}
                count={count}
                columns={columns}
                handleFormOpen={setFormOpen}
                waiting={waiting}
                refreshPage={() => {
                    setRefresh(!refresh);
                }}
                setPagesToFetch={setPagesToFetch}
                reqDelete={moderationDelete}
                setEdit={setEditId}
            />
            <FormController
                open={formOpen}
                data={data}
                propList={propList}
                disableSave={true}
                header={t('propList.product')}
                setEdit={setEditId}
                render={(input, handleTextChange, handleFileChange) => (
                    <ProductsForm
                        edit={editId}
                        setFormOpen={setFormOpen}
                        open={formOpen}
                        input={input}
                        callback={refresh}
                        setCallback={setRefresh}
                        handleTextChange={handleTextChange}
                        handleFileChange={handleFileChange}
                    />
                )}
                handleClose={() => {
                    setFormOpen(false);
                }}
                refreshPage={() => {
                    setRefresh(!refresh);
                }}
                reqPost={modificationPost}
                reqPatch={modificationPut}
                reqDelete={moderationDelete}
            />
        </>
    );
}
