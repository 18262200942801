import {
    ADD_ORDER, REMOVE_ORDER, QUANTITY_ORDER, TOTAL_ORDER,
    SELECTED_ORDER, CLEAR_ORDER, ADD_EDIT_ORDER, ORDER_DETAILS, MODIFICATION_ORDER
} from '../actionTypes';

export const addOrder = (data) => {
    return {
        type: ADD_ORDER,
        payload: data
    };
};
export const addEditOrder = (data) => {
    return {
        type: ADD_EDIT_ORDER,
        payload: data
    };
};
export const editQuantityOrder = (data) => {
    return {
        type: QUANTITY_ORDER,
        payload: data
    };
};
export const editModOrder = (data) => {
    return {
        type: MODIFICATION_ORDER,
        payload: data
    };
};
export const totalOrder = (data) => {
    return {
        type: TOTAL_ORDER,
        payload: data
    };
};
export const selectedOrders = (data) => {
    return {
        type: SELECTED_ORDER,
        payload: data
    };
};
export const removeOrder = (data) => {
    return {
        type: REMOVE_ORDER,
        payload: data
    };
};
export const clearOrder = () => {
    return {
        type: CLEAR_ORDER
    };
};
export const orderDetails = (data) => {
    return {
        type: ORDER_DETAILS,
        payload: data
    };
};

