import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Table from '../components/TableView';
import FormController from '../components/FormController';
import UsersForm from './forms/goods.form';
import { GoodsPropList } from '../../../constants/propListConstants';
import getColumns from '../../../constants/tableColumnConstants';
// import * as cookies from '../../../utils/Cookies';
import {
    goodsGet,
    goodPost,
    goodPut,
    goodDelete
} from '../../../utils/apiRequest';

export default function Users() {
    // const lang = cookies.get('lang')
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [formOpen, setFormOpen] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [pagesToFetch, setPagesToFetch] = useState([]);
    const [waiting, setWaiting] = useState(false);
    const [cId, setCId] = useState(null)
    const [filterGoodId, setFilterGoodId] = useState(null)
    const [postData, setPostData] = useState(null)
    const [categories, setCategories] = React.useState(null);
    const { t } = useTranslation();

    const columns = getColumns('goods', t);
    useEffect(() => {
        setWaiting(true);

        const prms = [];
        for (let i of pagesToFetch) prms.push(goodsGet(i, filterGoodId));

        Promise.all(prms)
            .then((res) => {
                const obj = [];
                res.forEach((el) => {
                    obj.push(...el.data.results);
                });
                setData([...data, ...obj]);
            })
            .catch((err) => {
                console.error(err);
                setData([]);
            })
            .finally(() => setWaiting(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagesToFetch, filterGoodId]);

    useEffect(() => {
        setData([]);
        setPagesToFetch([1]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refresh]);

    React.useMemo(() => {
        if (filterGoodId) setData([])
    }, [filterGoodId])
    return (
        <>
           <Table
                rows={data?.map(item => { return { ...item, unit_type: item?.unit_type?.name, category: item?.category?.name, category_id: item?.category?.name, category_n: item, branch: item?.branch?.name,vendor:item.vendor.name } })}
                count={count}
                columns={columns}
                waiting={waiting}
                handleFormOpen={setFormOpen}
                reqDelete={goodDelete}
                refreshPage={() => setRefresh(!refresh)}
                setPagesToFetch={setPagesToFetch}
                goodsFilter
                setFilterGoodId={setFilterGoodId}
            />
            <FormController
                open={formOpen}
                data={data}
                goodSS={true}
                postData={postData}
                categories={categories}
                propList={GoodsPropList}
                header={t('propList.user')}
                render={(input, handleTextChange, handleFileChange) => (
                    <UsersForm
                        input={input}
                        cId={cId}
                        categories={categories}
                        setCategories={setCategories}
                        setPostData={setPostData}
                        handleTextChange={handleTextChange}
                        handleFileChange={handleFileChange}
                    />
                )}
                handleClose={() => {
                    setFormOpen(false);
                }}
                refreshPage={() => {
                    setRefresh(!refresh)
                    console.log('refreshed')
                }}
                reqPost={goodPost}
                reqPatch={goodPut}
            />
        </>
    );
}
