import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import ProductFilter from '../../../pages/root/components/product.filter';
import GoodsFilter from '../../../pages/root/components/goods.filter';

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Typography,
    Paper,
    Checkbox,
    Box,
    Button,
    CircularProgress,
    makeStyles,
} from '@material-ui/core';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons';

import Confirm from '../../../components/Confirm';
import Alert from '../../../components/Alert';
import { useSelector } from 'react-redux';
import { VALUES, DARKVALUES, GREENVALUES } from '../../../constants/styleConstants';
import styled from '@material-ui/core/styles/styled';
import { ArrowForward, ArrowBack } from '@material-ui/icons';
import EmptyList from '../components/empty.list';

// Utility Functions
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

// Header

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: 'rgba(230, 246, 255, 0.5)',
        border: 'none',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function EnhancedTableHead(props) {
    const theme = useSelector((state) => state.theme);
    let colorValues;
    if (theme.name === 'dark') {
        colorValues = VALUES;
    } else if (theme.name === 'green') {
        colorValues = GREENVALUES;
    } else {
        colorValues = DARKVALUES;
    }
    const useTableHeadStyles = makeStyles((theme) => ({
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },

        colorPrimary: {
            background: colorValues.colorBgPrimary,
            color: colorValues.colorHeader,
        },
        c: {
            '& path': {
                fill: colorValues.colorHeader,
            },
        },
    }));

    const classes = useTableHeadStyles();
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    return (
        <TableHead className={classes.colorPrimary}>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        disabled={props.disableCheckbox}
                        className={classes.colorPrimary}
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                </TableCell>
                {props.columns.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            classes={{ icon: classes.c }}
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            <Typography className={classes.colorPrimary}>
                                {headCell.label}
                            </Typography>
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
                {props.componentTableCell}
            </TableRow>
        </TableHead>
    );
}
EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

// Styles

export default function EnhancedTable(props, { isOrder }) {
    const theme = useSelector((state) => state.theme);
    let colorValues;
    if (theme.name === 'dark') {
        colorValues = VALUES;
    } else if (theme.name === 'green') {
        colorValues = GREENVALUES;
    } else {
        colorValues = DARKVALUES;
    }
    const useStyles = makeStyles((theme) => ({
        root: {
            width: '100%',
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        table: {
            minWidth: 750,
        },

        //
        boxBtns: {
            marginBottom: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        boxBtn: {
            marginRight: '5px',
        },
        boxTable: {
            height: 1170,
            width: '100%',
            marginTop: '10px',
            overflow: 'auto hidden',
        },
        tablePagination: {
            background: colorValues.colorBgPrimary,
            color: colorValues.colorHeader,
        },
        progress: {
            position: 'absolute',
            top: '20%',
            left: '46%',
        },
        hidden: {
            display: 'none',
        },
        //
        colorPrimary: {
            color: colorValues.colorPrimary,
            background: colorValues.colorBgPrimary,
        },
    }));

    const classes = useStyles();
    const { t } = useTranslation();
    const { buttonCLick, handleClickbackBtn } = props;
    // console.log(props)
    // pagination
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const rowsInPage = 20;

    useEffect(() => {
        const current = props.rows.length;
        const required =
            props.count > 0
                ? (page + 1) * rowsPerPage > props.count
                    ? props.count
                    : (page + 1) * rowsPerPage
                : (page + 1) * rowsPerPage;
        const diff = required - current;
        // const cPage = Math.ceil((page * rowsPerPage) / rowsInPage + 1);
        const cPage = Math.ceil(current / rowsInPage + 1);

        if (diff > 0) {
            const arr = [];
            for (let i = cPage; i < cPage + Math.ceil(diff / rowsInPage); i++) {
                if (i > current / rowsInPage) arr.push(i);
            }
            if (arr.length > 0) props.setPagesToFetch(arr);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage]);
     
    // table functionality
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');
    const [selected, setSelected] = useState([]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = props.rows.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name, row) => {
        // console.log(row);
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        // console.log(newSelected);
        if(props?.setCId) props.setCId(row?.category_n)
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const isSelected = (name) => selected.indexOf(name) !== -1;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, props.rows.length - page * rowsPerPage);

    // logic
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [alert, setAlert] = useState({ open: false, severity: '', message: '' });

    function handleClickAddBtn() {
        if(props.setEdit){
            props.setEdit({})
        } 
        props.handleFormOpen({ isNew: true });
    }

    function handleClickEditBtn() {
        props.handleFormOpen({ id: selected[0] * 1 });
    }

    function handleClickDeleteBtn() {
        setDeleteConfirmOpen(true);
    }

    function handleConfirmDelete() {
        const prms = [];
        selected.forEach((el) => {
            prms.push(props.reqDelete(el * 1));
        });

        Promise.all(prms)
            .then((responds) => {
                responds.forEach((res) => {
                    if (res.status <= 200 || res.status > 300)
                        setAlert({
                            open: true,
                            severity: 'error',
                            message: Object.values(res.data)[0],
                        });
                });
                setAlert({ open: true, severity: 'success', message: 'Deleted' });

                setSelected([]);
                setDeleteConfirmOpen(false);
                // console.log('im callin');
                props.refreshPage();
            })
            .catch((err) => console.error(err));
    }

    function handleAlertClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }

        setAlert({ ...alert, open: false });
    }
    // function handleArrowInner(row) {
    //     buttonCLick(row)
    // }
    // t('table.buttons.add')
    return (
        <div className={classes.root}>
            <Box className={classes.boxBtns}>
                <div>
                    {
                        !props?.disableCreate && 
                        <Button
                            variant="contained"
                            color="primary"
                            data-testid="button"
                            className={clsx(classes.boxBtn)}
                            startIcon={<AddIcon />}
                            onClick={handleClickAddBtn}
                        >
                            {t('table.buttons.add')}
                        </Button>
                    }
                    {props?.componentButton && props?.rows[0]?.parent !== 1&&props?.historyParent.length>1 ? (
                        <Button
                            variant="contained"
                            color="primary"
                            className={clsx(classes.boxBtn)}
                            startIcon={<ArrowBack />}
                            onClick={handleClickbackBtn}
                        >
                            {t('table.buttons.back')}
                        </Button>
                    ) : null}
                    <Button
                        variant="contained"
                        className={clsx(classes.boxBtn, [selected.length !== 1 && classes.hidden])}
                        startIcon={<EditIcon />}
                        onClick={handleClickEditBtn}
                    >
                        {t('table.buttons.edit')}
                    </Button>
                    {props?.disableDelete ? null : (
                        <Button
                            variant="contained"
                            color="secondary"
                            className={clsx(classes.boxBtn, [
                                selected.length < 1 && classes.hidden,
                            ])}
                            startIcon={<DeleteIcon />}
                            onClick={handleClickDeleteBtn}
                        >
                            {t('table.buttons.delete')}
                        </Button>
                    )}
                </div>
                {props?.productFilter && (
                    <div>
                        <ProductFilter
                            setPagesToFetch={props.setPagesToFetch}
                            setFilterId={props.setFilterId}
                        />
                    </div>
                )}
                 {props?.goodsFilter && (
                    <div>
                        <GoodsFilter
                            setPagesToFetch={props.setPagesToFetch}
                            setFilterGoodId={props.setFilterGoodId}
                        />
                    </div>
                )}

            </Box>

            <Paper className={classes.paper}>
                <TableContainer style={{ position: 'relative' }}>
                    <CircularProgress
                        className={clsx(classes.progress, !props.waiting && classes.hidden)}
                        size={100}
                        color="inherit"
                    />

                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size="medium"
                        aria-label="enhanced table"
                        // localization={{ body:{ emptyDataSourceMessage:<h1 style={{marginTop:'6%', position:'absolute', top:'16%', marginLeft:'-70px', textAlign:'center'}}>No records to display</h1> } }}
                        // onRowClick={()=>console.log("sadf")}
                    >
                        <EnhancedTableHead
                            classification={props.classification}
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={props.rows.length}
                            columns={props.columns}
                            disableCheckbox={props.disableCheckbox}
                            componentTableCell={props.componentTableCell}
                        />
                        {props?.emptyList ? (
                            <TableBody>
                                <TableCell colSpan={12} style={{ textAlign: 'center' }}>
                                    <EmptyList height="400px" top="100px" />
                                </TableCell>
                            </TableBody>
                        ) : (
                            <TableBody>
                                {stableSort(props.rows, getComparator(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                        const isItemSelected = isSelected(row.id);
                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <StyledTableRow
                                                hover
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={row.id}
                                                selected={isItemSelected}
                                            >
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        onClick={
                                                            !props.disableCheckbox
                                                                ? (event) =>
                                                                      handleClick(event, row.id, row)
                                                                : null
                                                        }
                                                        disabled={props.disableCheckbox}
                                                        checked={isItemSelected}
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                    />
                                                </TableCell>

                                                {props.columns.map((el, i) => {
                                                    return (
                                                        <TableCell align="left" key={i}>
                                                            {row[el.id] || t('profile.nodata')}
                                                        </TableCell>
                                                    );
                                                })}
                                                {props.componentButton ? (
                                                    <TableCell>
                                                        <Button
                                                            variant="outlined"
                                                            onClick={() => buttonCLick(row)}
                                                            style={{
                                                                position: 'relative',
                                                                border: 'none',
                                                            }}
                                                        >
                                                            <ArrowForward />
                                                        </Button>
                                                    </TableCell>
                                                ) : null}
                                            </StyledTableRow>
                                        );
                                    })}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
                <TablePagination
                    classes={{ toolbar: classes.tablePagination }}
                    rowsPerPageOptions={[10, 20, 40, 60]}
                    component="div"
                    labelRowsPerPage={t('table.rows_per_page')}
                    count={props.count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
            <Confirm
                open={deleteConfirmOpen}
                header={t('table.deleteConfirm.header')}
                text={t('table.deleteConfirm.text')}
                handleClose={() => {
                    setDeleteConfirmOpen(false);
                }}
                handleConfirm={handleConfirmDelete}
            />
            <Alert
                open={alert.open}
                severity={alert.severity}
                message={alert.message}
                onClose={handleAlertClose}
            />
        </div>
    );
}
