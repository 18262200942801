import React, { useEffect, useState } from 'react';
import {
    Button,
    TextField,
    Grid,
    FormControlLabel,
    Checkbox,
    makeStyles,
    Divider,
    ButtonBase,
} from '@material-ui/core';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import defProduct from '../../../../assets/images/defProduct.webp';
import { postResponse, putResponse } from '../../../../requests/getApi';
import { useSelector } from 'react-redux';
import { VALUES, DARKVALUES, GREENVALUES } from '../../../../constants/styleConstants';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { categoryGet } from '../../../../utils/apiRequest';
import SendIcon from '@mui/icons-material/Send';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import LoadingButton from '@mui/lab/LoadingButton';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export default function ProductsForm(props) {
    const theme = useSelector((state) => state.theme);
    let colorValues;
    if (theme.name === 'dark') {
        colorValues = VALUES;
    } else if (theme.name === 'green') {
        colorValues = GREENVALUES;
    } else {
        colorValues = DARKVALUES;
    }
    const useStyles = makeStyles((theme) => ({
        imageBox: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        image: {
            height: '140px',
            width: '180px',
            marginBottom: '10px',
            border: '1px solid black',
            borderRadius: '5px',
            objectFit: 'contain',
        },
        uploadBtn: {
            padding: '4px',
        },
        root: {
            padding: '8px',
        },
        divider: {
            margin: '20px 0',
        },
        rightContainer: {
            display: 'flex',
            flexDirection: 'column',
        },

        //
        colorPrimary: {
            color: colorValues.colorPrimary,
            background: colorValues.colorBgPrimary,
        },
    }));
    const { edit } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const { callback, setCallback } = props;
    const [putReq, setPutReq] = useState(false);
    const [load, setLoad] = React.useState(false);
    const [state, setState] = React.useState({ name: '', order_in_list: null });
    const [error, setError] = React.useState({ name: false, order_in_list: false });
    const [postData, setPostData] = React.useState({ success: '', error: '' });
    console.log(props?.edit);
    useEffect(() => {
        if (edit) {
            setState({ name: edit?.name, order_in_list: '', created_date: edit?.created_date });
            setPutReq(true);
        }
    }, [edit]);
    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value,
        });
    };
    const handleSubmit = () => {
        setLoad(true);
        if (state?.name === '' || state?.order_in_list === null) {
            if (state?.name === '') {
                setError({ ...error, name: true });
            }
            setLoad(false);
        } else {
            postResponse(
                '/modifications/',
                { name: state?.name, order_in_list: parseInt(state?.order_in_list) },
                setPostData
            );
        }
    };
    const handleSubmitPut = () => {
        setLoad(true);
        if (state?.name === '' || state?.order_in_list === null) {
            if (state?.name === '') {
                setError({ ...error, name: true });
            }
            setLoad(false);
        } else {
            putResponse(
                `/modifications/${edit?.id}/`,
                { name: state?.name, order_in_list: parseInt(state?.order_in_list) },
                setPostData
            );
        }
    };
    React.useEffect(() => {
        if (!(postData?.success === '' && postData?.error === '')) {
            setLoad(false);
        }
        if (postData?.success !== '') {
            setCallback(!callback);
        }
    }, [postData]);
    React.useEffect(() => {
        if (state?.name !== '') setError({ ...error, name: false });
    }, [state]);
    return (
        <>
            <Divider className={clsx(classes.divider, classes.colorPrimary)} />

            <Grid container justify="space-around" style={{ alignItems: 'flex-start' }}>
                <Grid item sm={5} style={{ marginRight: 'auto' }}>
                    <TextField
                        fullWidth
                        error={error?.name || false}
                        variant="outlined"
                        id="name"
                        label={t('productProps.name')}
                        type="text"
                        value={state?.name || ''}
                        onChange={handleChange}
                        margin="dense"
                        style={{ margin: '10px 0' }}
                        name="name"
                    />
                    <TextField
                        fullWidth
                        error={error?.order_in_list || false}
                        type="number"
                        variant="outlined"
                        id="name"
                        label="Tartib raqami"
                        value={state?.order_in_list || ''}
                        onChange={handleChange}
                        margin="dense"
                        style={{ margin: '10px 0' }}
                        name="order_in_list"
                    />
                    {Object.keys(edit).length > 0 ? (
                        <LoadingButton
                            onClick={handleSubmitPut}
                            endIcon={
                                postData?.success !== '' ? (
                                    <CheckCircleOutlineIcon />
                                ) : postData?.error !== '' ? (
                                    <ErrorOutlineIcon />
                                ) : (
                                    <SendIcon />
                                )
                            }
                            style={
                                postData?.success !== ''
                                    ? { backgroundColor: 'green' }
                                    : postData?.error !== ''
                                    ? { backgroundColor: 'red' }
                                    : null
                            }
                            loading={load}
                            loadingPosition="end"
                            variant="contained"
                        >
                            {postData?.success === '' && postData?.error === ''
                                ? 'Yangilash'
                                : postData?.success !== '' && postData?.error == ''
                                ? 'Yangilandi'
                                : 'Xatolik yuz berdi'}
                        </LoadingButton>
                    ) : (
                        <LoadingButton
                            onClick={handleSubmit}
                            endIcon={
                                postData?.success !== '' ? (
                                    <CheckCircleOutlineIcon />
                                ) : postData?.error !== '' ? (
                                    <ErrorOutlineIcon />
                                ) : (
                                    <SendIcon />
                                )
                            }
                            style={
                                postData?.success !== ''
                                    ? { backgroundColor: 'green' }
                                    : postData?.error !== ''
                                    ? { backgroundColor: 'red' }
                                    : null
                            }
                            loading={load}
                            loadingPosition="end"
                            variant="contained"
                        >
                            {postData?.success === '' && postData?.error === ''
                                ? 'Yaratish'
                                : postData?.success !== '' && postData?.error == ''
                                ? 'Yaratildi'
                                : 'Xatolik yuz berdi'}
                        </LoadingButton>
                    )}
                </Grid>
            </Grid>
            <Divider className={clsx(classes.divider, classes.colorPrimary)} />
        </>
    );
}
