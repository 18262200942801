import { Box, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import noOrders from "../../../../../assets/images/noOrders.svg";
import Select from 'react-select';
import {
    TextField
} from '@material-ui/core';

// import './orderProduct.css';
import { useDispatch, useSelector } from 'react-redux';
import { editQuantityOrder, removeOrder, totalOrder, editModOrder } from '../../../../../redux/actions/OrderActions';
import { Delete } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import {getResponse} from "../../../../../requests/getApi"
const useStyles = makeStyles((theme) => ({
    deleteBtn: {
        '&:hover': {
            background: "rgba(255,158,150,0.42)",
            color: "rgb(255,96,61)",
        },
    },
}));

const ProductTable = () => {
    const { t } = useTranslation();
    const [, setQuantity] = useState(null);
    const [getDataSelect, setGetDataSelect] = useState({success:'', error:''})
    const [optionValue, setOptionValue] = useState([])
    const classes = useStyles();

    const dispatch = useDispatch();

    const orders = (state) => state.orders;
    const ordersData = useSelector(orders);

    let quant = null;
   
    function handleInputChange(e) {
        setQuantity(e.target.value);
        quant = e.target.value;
    }

    function handleDispatch(id) {
        dispatch(editQuantityOrder({ quant, id }));
    }
    function handleSelectChange(id,value) {
        let {label} = value;
        dispatch(editModOrder({ id, label }))
        console.log(id, value)
    }
    let total = 0;
    ordersData.orderData?.map(order => {
        const {price, quantity} = order;
        total = total += parseFloat(price) * quantity;
    });
    ordersData.editedOrderData?.map(order => {
        const {price, quantity} = order;
        total = total += parseFloat(price) * quantity;
    });
    useEffect(()=>{
        dispatch(totalOrder(total))
    },[total])
    useEffect(()=>{getResponse('/modifications/?page=1&page_size=20', setGetDataSelect)},[])
    useEffect(()=>{
        if(getDataSelect?.success!==''){
            let data = getDataSelect?.success?.data?.results.map(item=>{
                return{
                    value:item?.id,
                    label:item?.name,
                }
            })
            setOptionValue(data)
        }
    },[getDataSelect])
    const deleteProduct = (id) => {
        console.log(id);
        dispatch(removeOrder(id));
    }
    console.log(getDataSelect)
    if (ordersData.orderData.length === 0 && ordersData.editedOrderData.length ===0) {
        return (
            <Box p={3} boxShadow={1}>
                <div style={{textAlign: "center"}}>
                    <img src={noOrders}  width={160} height={140} alt="no orders"/>
                    <h3 style={{color: "#777"}}>{t('orderProps.noOrder')}</h3>
                </div>
            </Box>
        );
    }
      const customStyles = {
        option: (provided, state) => ({
          ...provided,
        }),
        control: () => ({
            width: '100%',
            display:'flex',
            border: '1px solid #ccc',
            borderRadius: '4px'
        }),
        singleValue: (provided, state) => {
          const opacity = state.isDisabled ? 0.5 : 1;
          const transition = 'opacity 300ms';
      
          return { ...provided, opacity, transition };
        }
      }
    return (
        <Box p={2} boxShadow={1} style={{position: 'relative', zIndex:999}}>
            {ordersData.editedOrderData?.map(order => {
                return (
                    <Grid key={order.id} container alignItems="center" spacing={1}>
                        <Grid sm={5} item>
                            <p className="product-name">
                                {order.name ? order.name : order.name_uz}
                            </p>
                        </Grid>
                       
                        <Grid item sm={2}>
                            <TextField
                                disabled
                                id="standard-disabled"
                                label={t('orderProps.price')}
                                defaultValue={order.price}
                            />
                        </Grid>

                        <Grid item sm={2}>
                            <TextField
                                disabled
                                id="standard-disabled"
                                label={t('orderProps.total')}
                                value={(order.quantity * parseFloat(order.price)).toString()}
                            />
                        </Grid>

                        <Grid sm={2} item>
                            <TextField
                                fullWidth
                                variant="outlined"
                                size="small"
                                label={t('orderProps.quantity')}
                                type="number"
                                defaultValue={order?.quantity}
                                name="quantity"
                                InputProps={{ inputProps: { min: 1 } }}
                                onChange={(e) => {
                                    handleInputChange(e);
                                    handleDispatch(order.id);
                                }}
                            />
                        </Grid>
                        <Grid item sm={1}>
                            <IconButton className={classes.deleteBtn} onClick={() => deleteProduct(order.id)}>
                                <Delete/>
                            </IconButton>
                        </Grid>
                        <Grid item sm={12}>
                            <Select
                                options={optionValue || []} 
                                styles={customStyles} 
                                className="select_r" 
                                placeholder="Qo'shimcha"
                                onChange={(e) =>handleSelectChange(order.id,e)}
                            />
                        </Grid>
                    </Grid>
                );
            })}
            {ordersData.orderData?.map(order => {
                return (
                    <Grid key={order.id} container alignItems="center" spacing={1}>
                        <Grid sm={5} item>
                            <p className="product-name">
                                {order.name ? order.name : order.name_uz}
                            </p>
                        </Grid>
                        <Grid item sm={2}>
                            <TextField
                                disabled
                                id="standard-disabled"
                                label={t('orderProps.price')}
                                defaultValue={order.price}
                            />
                        </Grid>

                        <Grid item sm={2}>
                            <TextField
                                disabled
                                id="standard-disabled"
                                label={t('orderProps.total')}
                                value={(order.quantity * parseFloat(order.price)).toString()}
                            />
                        </Grid>
                        {/* Quantity */}
                        <Grid sm={2} item>
                            <TextField
                                fullWidth
                                variant="outlined"
                                size="small"
                                label={t('orderProps.quantity')}
                                type="number"
                                value={order.quantity || 1}
                                name="quantity"
                                onChange={(e) => {
                                    handleInputChange(e);
                                    handleDispatch(order.id);
                                }}
                            />
                        </Grid>
                        <Grid item sm={1}>
                            <IconButton className={classes.deleteBtn} onClick={() => deleteProduct(order.id)}>
                                <Delete/>
                            </IconButton>
                        </Grid>
                        <Grid item sm={12}>
                        <Select
                                options={optionValue || []} 
                                styles={customStyles} 
                                className="select_r" 
                                placeholder="Qo'shimcha"
                                onChange={(e) =>handleSelectChange(order.id,e)}
                            />
                        </Grid>
                    </Grid>
                );
            })}
        </Box>
    );
};

export default ProductTable;