import React from 'react'
import Table from "./table"
import getColumns from '../../../../constants/tableColumnConstants';
import Loading from "./loading"
import { useTranslation } from 'react-i18next';

const TableOne = ({ data, loading, page, perPage, setFirstClick, setPaging, firstClick, handleChangePage, handleChangePageRow }) => {
   
    const { t } = useTranslation();
    const columns = getColumns('productRecipe', t);
    // console.log(data)
    return (
        <div>
            <div className="title_19sp">{t('orderProps.products')}</div>
            {data?.loading ? <Loading /> :
                <Table 
                    firstClick={firstClick} 
                    columns={columns} 
                    data={data?.results} 
                    listSize={data?.count} 
                    loading={true} 
                    type="one" 
                    page={page}
                    loading={loading}
                    perPage={perPage}
                    setFirstClick={setFirstClick} 
                    setPaging={setPaging} 
                    handleChangePage={handleChangePage}
                    handleChangePageRow={handleChangePageRow}
                />
            }
        </div>
    )
}

export default TableOne
