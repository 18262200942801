import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import langEN from './data/en.json';
import langRU from './data/ru.json';
import langUZ from './data/uz.json';
import { login } from '../utils/apiRequest';

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: langEN,
            ru: langRU,
            uz: langUZ
        },
        /* default language when load the website in browser */
        // lng: 'uz',
        /* When react i18next not finding any language to as default in borwser */
        fallbackLng: 'uz',
        /* debugger For Development environment */
        debug: false,
        ns: ['translations'],
        defaultNS: 'translations',
        keySeparator: '.',
        interpolation: {
            escapeValue: false,
            formatSeparator: ','
        },
        react: {
            wait: true,
            bindI18n: 'languageChanged loaded',
            bindStore: 'added removed',
            nsMode: 'default'
        }
    }).then(res => {
        console.log(res)
    }
);

export default i18n;
