import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { Grid, TextField, Divider, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { VALUES, DARKVALUES, GREENVALUES } from '../../../../constants/styleConstants';



export default function UsersForm(props) {
    const theme = useSelector((state) => state.theme);
    let colorValues;
    if (theme.name === 'dark') {
        colorValues = VALUES;
    } else if (theme.name === 'green') {
        colorValues = GREENVALUES;
    } else {
        colorValues = DARKVALUES;
    }
    //
const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        width: '100%',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    divider: {
        margin: '20px 0',
    },
    rightContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    sectionHeader: {
        margin: '-20px auto 10px auto',
        textAlign: 'center',
    },

    //
    colorPrimary: {
        color: colorValues.colorPrimary,
        background: colorValues.colorBgPrimary,
    },
}));
//
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <>
            <Grid container justify="space-around">
                <Grid item sm={5}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        id="first_name"
                        label={t('userProps.first_name')}
                        type="text"
                        value={props.input['first_name'] || ''}
                        onChange={props.handleTextChange('first_name')}
                        margin="dense"
                    />
                </Grid>
                <Grid item sm={5}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        id="last_name"
                        label={t('userProps.last_name')}
                        type="text"
                        value={props.input['last_name'] || ''}
                        onChange={props.handleTextChange('last_name')}
                        margin="dense"
                    />
                </Grid>
            </Grid>
            <Divider className={clsx(classes.divider, classes.colorPrimary)} />

            <Grid container justify="space-around">
                <Grid item sm={5}>
                    <TextField
                        fullWidth
                        required
                        variant="outlined"
                        id="phone"
                        label={t('userProps.phone')}
                        type="tel"
                        value={props.input['phone'] || ''}
                        onChange={props.handleTextChange('phone')}
                        margin="dense"
                    />
                </Grid>
                <Grid item sm={5}>
                    <TextField
                        fullWidth
                        required
                        variant="outlined"
                        id="password"
                        label={t('userProps.password')}
                        type="password"
                        value={props.input['password'] || ''}
                        onChange={props.handleTextChange('password')}
                        margin="dense"
                    />
                </Grid>
                <Grid item sm={5}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        id="username"
                        label={t('userProps.username')}
                        type="text"
                        value={props.input['username'] || ''}
                        onChange={props.handleTextChange('username')}
                        margin="dense"
                    />
                </Grid>
                <Grid item sm={5}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        id="email"
                        label={t('userProps.email')}
                        type="email"
                        value={props.input['email'] || ''}
                        onChange={props.handleTextChange('email')}
                        margin="dense"
                    />
                </Grid>
            </Grid>
        </>
    );
}
