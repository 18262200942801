import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Grid, TextField, Divider, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        width: '100%',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    divider: {
        margin: '20px 0',
    },
    rightContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    sectionHeader: {
        margin: '-20px auto 10px auto',
        textAlign: 'center',
    },

    colorPrimary: {

    },
}));
//

export default function UsersForm(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <>
        <Divider className={clsx(classes.divider, classes.colorPrimary)} />
            <Grid container justify="space-around">
                <Grid container justify="space-around">
                    <Grid item sm={5}>
                        <TextField
                            fullWidth
                            required
                            variant="outlined"
                            id="name"
                            label={t('categoryProps.name')}
                            type="text"
                            value={props.input['name'] || ''}
                            onChange={props.handleTextChange('name')}
                            margin="dense"
                        />
                    </Grid>
                    <Grid item sm={5} className={classes.rightContainer}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            id="name_ru"
                            label={t('categoryProps.name_ru')}
                            type="text"
                            value={props.input['name_ru'] || ''}
                            onChange={props.handleTextChange('name_ru')}
                            margin="dense"
                        />
                        <TextField
                            fullWidth
                            variant="outlined"
                            id="name_uz"
                            label={t('categoryProps.name_uz')}
                            type="text"
                            value={props.input['name_uz'] || ''}
                            onChange={props.handleTextChange('name_uz')}
                            margin="dense"
                        />
                        <TextField
                            fullWidth
                            variant="outlined"
                            id="name_en"
                            label={t('categoryProps.name_en')}
                            type="text"
                            value={props.input['name_en'] || ''}
                            onChange={props.handleTextChange('name_en')}
                            margin="dense"
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Divider className={clsx(classes.divider, classes.colorPrimary)} />
               
        </>
    );
}
