import { Redirect, Route } from 'react-router-dom';
import * as cookies from '../utils/Cookies';

export default function ProtectedRoute({ children, ...rest }) {
    const token = cookies.get('jwt');

    return (
        <Route
            {...rest}
            render={({ location }) =>
                token ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
}
