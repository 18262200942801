import styled from "styled-components"

export const Component = styled.div`
    position:relative;
    // top:${prev=>prev.top};
    height:${prev=>prev.height};
    margin: 0 auto;
    img{
        width:140px;
        height:140px;
        margin:0 auto;
    }
    .title{
        font-size:1.2rem;
        // font-weight:bold;
        color:#2c3e4f;
    }
    .wrapper{
        position:absolute;
        top:${prev=>prev.top};
        width:100%;
        display:flex;
        flex-direction:column;
        // background:red;
    }
`