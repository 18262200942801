import React, { useState, useEffect, useMemo, useCallback } from 'react';
import Alert from "../../../../components/Alert"
import { useTranslation } from 'react-i18next';
import { Grid, TextField, makeStyles, IconButton, DialogContent, Dialog, DialogTitle } from '@material-ui/core';
import { TABLE_TYPE } from '../../../../constants/tableConstans';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import { useSelector } from 'react-redux';
import { VALUES, DARKVALUES, GREENVALUES } from '../../../../constants/styleConstants';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { categoryGoodGet } from '../../../../utils/apiRequest';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { getResponse } from "../../../../requests/getApi"
import { classincomeGet, tariffGet } from '../../../../utils/apiRequest'
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import GoodsDialog from "./GoodsDialog";
import Table from '../../components/TableViewer';
import getColumns from '../../../../constants/tableColumnConstants';
import IncomeTable from './IncomeTable';
import { postApiResponse, putApiResponse } from "../../../../requests/getApi"
import { Add as AddIcon, Close } from '@material-ui/icons';
import cyan from '@material-ui/core/colors/cyan';


export default function UsersForm({ input, refresh, setRefresh, setFormOpen, fOpen, handleTextChange }) {
    const theme = useSelector((state) => state.theme);
    let colorValues;
    if (theme.name === 'dark') {
        colorValues = VALUES;
    } else if (theme.name === 'green') {
        colorValues = GREENVALUES;
    } else {
        colorValues = DARKVALUES;
    }

    const useStyles = makeStyles((theme) => ({
        formControl: {
            margin: theme.spacing(1),
            width: '100%',
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        divider: {
            margin: '20px 0',
        },
        rightContainer: {
            display: 'flex',
            flexDirection: 'column',
        },
        sectionHeader: {
            margin: '-20px auto 10px auto',
            textAlign: 'center',
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            border: '1px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            display: 'inline-block',
            marginTop: '0'
        },
        tableclass: {
            marginBottom: "0"
        },
        box: {
            margin: "0",
        },

        //
        colorPrimary: {
            color: colorValues.colorPrimary,
            background: colorValues.colorBgPrimary,
        },
        marginBottom: {
            marginBottom: "5rem",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex start"
        },
        button: {
            display: 'inline-block',

        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: "300px"
        },
        tableList: {
            width: '50%',
        },
        mainwrapper: {
            display: 'flex',

        },
        button: {
            margin: theme.spacing(1),

            display: 'inline'
        },
        gridStyle: {
            marginBottom: "1rem",
            marginRight: "3rem",
            display: "flex"
        },
        wrapper: {
            display: 'flex',
            width: "50%",
            flexWrap: 'wrap',
            // marginBottom: '1rem'
        }
    }));

    const [formOpen, setFormOpenw] = useState(false);
    const classes = useStyles();
    const [isAddModalOpen, setAddModal] = useState(false);
    const [globalClass, setGlobalClass] = useState([]);
    const [goodsIdApi, setGoodsIdApi] = useState({ error: '', success: '' })
    const [modalLoad, setModalLoad] = useState(false)
    const [goods, setGoods] = useState(null)
    const { t } = useTranslation();
    const columns_tariff = getColumns('tariff', t);
    const [postState, setPostState] = useState({ data: null, loading: false })
    const [branchItems, setBranchItems] = useState({ success: '', error: '' })
    const [goodsCategory, setGoodsCategory] = useState({ success: '', error: '' })
    const [tableList, setTableList] = useState([]);
    

    const [categories, setCategories] = React.useState(null);
    const kanban = (state) => state.kanban;
    const [tariffs, setTariffs] = useState([]);
    const [formProps, setFormProp] = useState({
        status: null,
    });

    const handleSelectChange = (e) => {
        setFormProp({
            ...formProps,
            [e.target.name]: e.target.value
        });
    };

    const [weself, setWeself] = useState(false);
    useEffect(() => {
        if (formProps.order_type === 'weself') {
            setWeself(true);
        } else {
            setWeself(false);
        }
    }, [handleSelectChange]);

    useEffect(() => { 
        categoryGoodGet().then(res => setCategories(res.data.results)) 
    }, [])

    React.useEffect(() => {
        if (goods !== null) {
            setModalLoad(true);//
            classincomeGet(goods).then((res) => {
                const productCategories = res.data?.results;
                productCategories(productCategories);
                setGlobalClass(productCategories);
                setModalLoad(false)
            });
        }
    }, [goods])
    const [waiting, setWaiting] = useState(false);
    const [vendor, setVendor] = useState(null)

    useEffect(() => {
        getResponse('/branches/?page=1&page_size=10', setBranchItems)
        getResponse(`/vendors/`, setVendor)
    }, [])
    
    useEffect(() => {
        if (isAddModalOpen === true) {
            getResponse('/good-category/?page=1&page_size=20', setGoodsCategory)
        }
    }, [isAddModalOpen])

    React.useEffect(() => {
        if (input?.goods && input?.goods !== 1) {
            getResponse(`/good/${input?.goods}/`, setGoodsIdApi)
        }
    }, [input?.goods])
    
    React.useEffect(() => {
        if (input?.items?.length > 0) {
            setTableList(
                input?.items?.map(item => (
                    {
                        name: item?.good?.name,
                        good_id: item?.good?.id,
                        unit_type_id: item?.good?.unit_type?.id,
                        quantity: parseInt(item?.quantity),
                        price: parseInt(item?.price),
                        sum: parseInt(item?.sum),
                        expired_date: item?.expired_date,
                        description: item?.description,
                    }
                ))
            )
        }
    }, [input?.items])
    
    const [user, setUser] = useState(0)

    let cloneUser = 0
    for(let i = 0; i < tableList.length; i++) {
        cloneUser += Number(tableList[i].sum)
    }

    useEffect(() => {
    if(cloneUser !== 0) {
        setUser(cloneUser)
    }
    }, [cloneUser])

    const handleSubmit = () => {
        input.total_sum = user
        let obj = {
            name: input.name,
            total_sum: input.total_sum,
            courier_name: input.courier_name,
            courier_phone: input.courier_phone,
            branch_id: input?.branch?.id ? input?.branch?.id : input.branch,
            vendor_id: input?.vendor?.id ? input?.vendor?.id : input.vendor,
            created_date: input.created_date,
            items: tableList,
        }
        console.log(obj)
        if(fOpen.hasOwnProperty('id')){
            putApiResponse(`/income/${fOpen.id}/`, obj, setPostState)
        }else
            postApiResponse('/income/', obj, setPostState)
    }

    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };
    useEffect(() => {
        if (postState?.success) {
            setFormOpen(false)
            setRefresh(prev => !prev)
        }
    }, [postState])
   
    return (
        <>
            {postState?.success && <Alert open={true} message="Created successfully" />}
            {postState?.error && <Alert open={true} message={postState?.data?.status + " " + postState?.data?.statusText} severity="error" />}
            <div className={classes.mainwrapper}>
                
                <div className={classes.wrapper}>
                    <Grid item className={classes.gridStyle} sm={5}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label={t('incomeProps.created_date')}
                            type="date"
                            required
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={input['created_date'] || ''}
                            onChange={handleTextChange('created_date')}
                            margin="dense"
                        />
                    </Grid>
                    <Grid item className={classes.gridStyle} sm={5}>
                        <FormControl style={{ width: '100%', marginTop: 8, marginBottom:4 }} size="small" variant="outlined">
                            <InputLabel id="demo-simple-select-outlined-label">{t('incomeProps.branch')}</InputLabel>
                            <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            name="branch_id"
                            value={input['branch']?.id || input['branch'] || ''}
                            onChange={handleTextChange('branch')}
                            >
                                {
                                    branchItems?.success?.data?.results.map((prev) => (
                                        <MenuItem value={prev?.id} key={prev?.id}>
                                            {prev?.name}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item className={classes.gridStyle} sm={5}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            id="courier_name"
                            label={t('incomeProps.name')}
                            required
                            type="text"
                            value={input['name'] || ''}
                            onChange={handleTextChange('name')}
                            margin="dense"
                        />
                    </Grid>
                    <Grid item className={classes.gridStyle} sm={5}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            id="courier_name"
                            label={t('incomeProps.courier_name')}
                            type="text"
                            required
                            value={input['courier_name'] || ''}
                            onChange={handleTextChange('courier_name')}
                            margin="dense"
                        />
                    </Grid>

                    <Grid item className={classes.gridStyle} sm={5} >
                        <FormControl style={{ width: '100%', marginTop: 8, marginBottom:4 }} size="small" variant="outlined">
                            <InputLabel id="demo-simple-select-outlined-label">{t('incomeProps.vendor')}</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                name="vendor_id"
                                value={input['vendor']?.id || input['vendor'] || ''}
                                onChange={handleTextChange('vendor')}
                            >
                                {
                                    vendor?.success?.data?.results?.map((prev) => (
                                        <MenuItem value={prev?.id} key={prev?.id}>
                                            {prev?.name}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item className={classes.gridStyle} sm={5}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            id="courier_phone"
                            required
                            label={t('incomeProps.courier_phone')}
                            type="number"
                            value={input['courier_phone'] || ''}
                            onChange={handleTextChange('courier_phone')}
                            margin="dense"
                        />
                    </Grid>

                </div>
                <div className={classes.tableList}>
                    <Grid>
                        <Button
                            variant="outlined"
                            fullWidth
                            className="add-btn"
                            size="large"
                            onClick={() => setAddModal(true)}
                        >
                            <AddIcon />
                        </Button>
                        {weself === false && (
                            <Grid container spacing={5} style={{ marginTop: '1rem' }}>
                                <Grid item xs={6}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Dialog
                                            maxWidth={'md'}
                                            // open={kanbanData?.tariffDialogOpen}
                                            onClose={handleClose}
                                            aria-labelledby="alert-dialog-title"
                                            aria-describedby="alert-dialog-description"
                                        >
                                            <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
                                                <div style={{ fontSize: '1.2rem' }} className={classes.sectionHeader}>
                                                    {t('tariffdelivery_price')}
                                                </div>
                                                <IconButton aria-label="close" className={classes.closeButton}
                                                    onClick={handleClose}>
                                                    <Close />
                                                </IconButton>
                                            </DialogTitle>
                                            <DialogContent>
                                                <Table
                                                    rows={tariffs}
                                                    type={TABLE_TYPE.tariff}
                                                    columns={columns_tariff}
                                                    waiting={waiting}
                                                    handleFormOpen={setFormOpenw}
                                                    refreshPage={() => setRefresh(!refresh)}
                                                />
                                            </DialogContent>
                                        </Dialog>
                                    </div>
                                </Grid>
                            </Grid>
                        )}
                        <GoodsDialog
                            open={isAddModalOpen}
                            setOpen={setAddModal}
                            tableList={tableList}
                            setTableList={setTableList}
                            goodsCategory={goodsCategory}
                        />
                    </Grid>
                    <Grid >
                        <IncomeTable setTableList={setTableList} tableList={tableList} className={classes.tableclass} />
                    </Grid>
                </div>
            </div>
            <Button
                variant="outlined"
                color="primary"
                size="large"
                className={classes.button}
                startIcon={<SaveIcon />}
                onClick={handleSubmit}
            >
                Save
            </Button>
            <Box display="flex" flexDirection="row-reverse" p={1} className={classes.box} >
                <Paper className={classes.paper} style={{ width: '250px' }}>
                    <Grid item>
                        <TextField
                            fullWidth
                            required
                            variant="outlined"
                            id="total_sum"
                            label={t('incomeProps.total_sum')}
                            type="text"
                            value={user}
                            onChange={e => setUser(e.target.value)}
                            margin="dense"
                        />

                    </Grid>
                </Paper>
            </Box>
        </>
    );
}

