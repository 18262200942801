import {
    COURIER_DIALOG, CALL_DIALOG,
    SELECTED_COURIER, SELECTED_COURIER_CLEAR,
    SELECTED_KANBAN,
    SELECTED_KANBAN_CLEAR,
    SELECTED_CALL,
    SELECTED_CALL_CLEAR, SELECTED_TARIFF,
    TARIFF_DIALOG, SELECTED_TARIFF_CLEAR, USER_DIALOG, SELECTED_ADDRESS_TEXT, SELECTED_BRANCH
} from '../actionTypes';


export const selectedKanban = (data) => {
    return {
        type: SELECTED_KANBAN,
        payload: data
    };
};
export const selectedKanbanClear = () => {
    return {
        type: SELECTED_KANBAN_CLEAR
    };
};
export const selectedCall = (data) => {
    return {
        type: SELECTED_CALL,
        payload: data
    };
};
export const selectedAddressText = (data) => {
    return {
        type: SELECTED_ADDRESS_TEXT,
        payload: data
    };
};
export const selectedBranch = (data) => {
    return {
        type: SELECTED_BRANCH,
        payload: data
    };
};
export const selectedCallClear = () => {
    return {
        type: SELECTED_CALL_CLEAR
    };
};
export const selectedTariffClear = () => {
    return {
        type: SELECTED_TARIFF_CLEAR
    };
};
export const selectedCourier = (data) => {
    return {
        type: SELECTED_COURIER,
        payload: data
    };
};
export const courierDialog = (data) => {
    return {
        type: COURIER_DIALOG,
        payload: data
    };
};
export const tariffDialog = (data) => {
    return {
        type: TARIFF_DIALOG,
        payload: data
    };
};
export const selectedTariff = (data) => {
    return {
        type: SELECTED_TARIFF,
        payload: data
    };
};
export const callDialog = (data) => {
    return {
        type: CALL_DIALOG,
        payload: data
    };
};
export const selectedCourierClear = () => {
    return {
        type: SELECTED_COURIER_CLEAR
    };
};
export const userDialog = (data) => {
    return {
        type: USER_DIALOG,
        payload: data
    };
};
