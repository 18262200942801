import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Card,
    CardContent,
    Grid,
    LinearProgress, TextField, FormControl, InputLabel, Select, MenuItem
} from '@material-ui/core';

import { getLang } from '../../../../../utils/Cookies';
import { productGetByCategoryId } from '../../../../../utils/apiRequest';
import {
    addOrder,
    clearOrder,
    editQuantityOrder,
    removeOrder,
    selectedOrders
} from '../../../../../redux/actions/OrderActions';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { Close, ArrowBack, ArrowForward, Add, Remove, Delete, Add as AddIcon } from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import orderCategory from "../../../../../assets/images/orderCategory.png";
import orderProduct from "../../../../../assets/images/orderProduct.png";
import { selectedKanbanClear } from '../../../../../redux/actions/KanbanActions';
import { useTranslation } from 'react-i18next';
import { orderPropList as propList } from '../../../../../constants/propListConstants';
import clsx from 'clsx';
import ProductTable from './ProductTable';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
    dialog: {
        maxWidth: 800,
        margin: 'auto'
    },
    dialogContent: {
        padding: '16px 26px'
    },
    dialogTitle: {
        borderBottom: '1px solid rgba(0,0,0,0.1)',
        display: 'flex!important',
        justifyContent: 'space-between',
    },
    card: {
        cursor: 'pointer',
    },
    cardContent: {
        padding: '16px !important',
        cursor: 'pointer',
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        color: "#166280",
        background: "rgba(0,250,154,0.1)",
        transition: "0.3s",
        "&:hover": {
            color: "lightseagreen",
        },
    },
    cardActive: {
        background: "rgba(0,250,154,0.5)",
    },
    closeButton: {
        position: 'absolute',
        right: '0.5rem',
        top: '0.5rem'
    },
    orderCategory: {
        boxShadow: "rgb(0 0 0 / 10%) 0px 4px 16px",
        borderRadius: '50%',
    },
    dialogAction: {
        background: "rgba(0,0,0,0.05)",
    }
});

function Categories({ productCategories, lang, handleClick }) {
    const classes = useStyles();
    return (
        <Grid container spacing={2}>
            {
                productCategories.map((category) => (
                    <Grid item  sm={6} key={category.id}>
                        <Card elevation={0} className={classes.card} onClick={() => handleClick(category.id)}>
                            <CardContent className={classes.cardContent}>
                                <div style={{display: 'flex', alignItems: "center"}}>
                                    <img className={classes.orderCategory} src={orderCategory} width={40} height={40} alt="category"/>
                                    <div style={{marginLeft: "0.5rem", fontSize: "1rem", fontWeight: "bold"}}>
                                        {category[`name_${lang ? lang : "uz"}`]}
                                    </div>
                                </div>
                                <IconButton>
                                    <ArrowForward/>
                                </IconButton>
                            </CardContent>
                        </Card>
                    </Grid>
                ))
            }
        </Grid>
    );
}

function Products({ list, lang }) {
    const classes = useStyles();
    const { t } = useTranslation();

    const orders = (state) => state.orders;
    const ordersData = useSelector(orders);

    const dispatch = useDispatch();

    const [selected, setSelected] = useState([]);
    const [quantityDialog,setQuantityDialog] = useState(false);
    const [quantity, setQuantity] = useState(null);

    function handleClick(obj) {
        console.log(obj);
        const existOrder = ordersData.orderData.find(order => order.id === obj.id) ||
            ordersData.editedOrderData.find(order => order.id === obj.id);

        if (!existOrder) {
            setQuantityDialog(true);
            setSelected(obj)
            dispatch(addOrder(obj));
            dispatch(selectedOrders(obj.id))
        } else {
            dispatch(removeOrder(obj.id));
        }
    }
    let quant = null;

    function handleInputChange(e) {
        setQuantity(e.target.value);
        quant = e.target.value;
    }
    function handleDispatch(id) {
        dispatch(editQuantityOrder({ quant, id }));
    }
    const handleCloseQuantity = () => {
        setQuantityDialog(false);
    }
    // console.log(list, "hjdshbsdbsajdbdsabd")

    return (
        <Grid container spacing={2}>
            {
                list.map((product) => (
                    <Grid item sm={6} key={product.id}>
                        <Card elevation={0}>
                            <CardContent className={
                                ordersData.selectedOrders.includes(product.id) ?
                                    `${classes.cardContent} ${classes.cardActive}`: classes.cardContent}
                            >
                                <div style={{display:"flex"}}>
                                    <img className={classes.orderCategory} src={orderProduct} width={40} height={40} alt="product"/>
                                    <div style={{marginLeft:"0.5rem"}}>
                                        <div style={{fontSize: "14px", fontWeight: "bold"}}>{product[`name_${lang ? lang : "uz"}`]}</div>
                                        <div style={{fontSize: "12px", fontWeight: "bold"}}>Price: {product['price']}</div>
                                    </div>
                                </div>
                                {ordersData.selectedOrders.includes(product.id) ? (
                                    <IconButton onClick={() => handleClick(product)}>
                                        <Delete/>
                                    </IconButton>
                                ) : (
                                    <IconButton onClick={() => handleClick(product)}>
                                        <Add/>
                                    </IconButton>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                ))
            }
            <Dialog
                maxWidth={'md'}
                open={quantityDialog}
                onClose={handleCloseQuantity}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
                    <div style={{fontSize: "1.2rem"}} className={classes.sectionHeader}>
                        {t('orderProps.addQuantity')}
                    </div>
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleCloseQuantity}>
                        <Close/>
                    </IconButton>
                </DialogTitle>
                <DialogContent>

                    <TextField
                        fullWidth
                        autoFocus
                        variant="outlined"
                        size="small"
                        label={t('orderProps.quantity')}
                        defaultValue={1}
                        type="number"
                        name="quantity"
                        style={{marginTop: "1rem"}}
                        InputProps={{ inputProps: { min: 1 } }}
                        onChange={(e) => {
                            handleInputChange(e);
                            handleDispatch(selected.id);
                        }}
                    />

                </DialogContent>
                <DialogActions className={classes.dialogAction} style={{padding: "0.5rem"}}>
                    <Button onClick={handleCloseQuantity} color="primary">
                        {t('orderProps.cancel')}
                    </Button>
                    <Button
                        onClick={handleCloseQuantity}
                        color="primary"
                        style={{marginLeft: "1rem"}}
                        className={classes.saveBtn}
                        autoFocus>
                        {t('orderProps.saveChanges')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}

export default function ProductDialog({ open, setOpen, productCategories = [] }) {
    const [lang, setLang] = useState("uz");
    const [category, setCategory] = useState(null);
    const [loading, setLoader] = useState(false);
    const [products, setProducts] = useState([]);
    const classes = useStyles();
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const handleClose = () => {
        setOpen(false);
        setTimeout(() => {
            setCategory(null);
            setProducts([]);
        }, 300);
    };

    function handleCategoryChange(categoryId) {
        setLoader(true);
        setCategory(categoryId);
        getCategories(categoryId);
    };

    function getCategories(categoryId) {
        productGetByCategoryId(categoryId, 1, 100).then((res) => {
            if (res) {
                console.log(res);
                setLoader(false);
                setProducts(res.data.results);
            }
        });
    }

    useEffect(() => {
        setLang(getLang());
    }, []);

    return (
        <Dialog
            onClose={handleClose}
            open={open}
            maxWidth={'lg'}
            className={classes.dialog}
        >
            {
                loading ? <LinearProgress/> : null
            }
            
            <DialogTitle className={classes.dialogTitle}>
                {category ? (
                    <div style={{
                        position: 'absolute', top: "0.5rem", left: "0.5rem"
                    }}
                         onClick={() => {
                            setCategory(null);
                         }}
                    >
                        <IconButton>
                            <ArrowBack/>
                        </IconButton>
                    </div>
                ) : null}
                <div style={{marginLeft: '3rem'}}>
                    {!!category ? t('orderProps.products') : t('orderProps.productCategory')}
                </div>
                <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                    <Close/>
                </IconButton>
            </DialogTitle>

            <DialogContent className={classes.dialogContent}>
                {
                    !!category && !loading ? (
                        <Products
                            lang={lang}
                            list={products}
                        />
                    ) : (
                        <Categories
                            lang={lang}
                            productCategories={productCategories}
                            handleClick={handleCategoryChange}
                        />
                    )
                }

            </DialogContent>

            {/*<DialogActions>*/}
            {/*    <Button autoFocus onClick={handleClose} color="primary">*/}
            {/*        Save changes*/}
            {/*    </Button>*/}
            {/*</DialogActions>*/}

        </Dialog>
    );
}