import axios from "axios";
import * as cookies from '../utils/Cookies';

export const token = cookies.get('jwt');
const baseUrl = "http://sevimli-lavash.uz/api"

export const getApiFunction = async() => {
    await axios.get('http://sevimli-lavash.uz/api/statusorder/', {
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
        }})
        .then(response => localStorage.setItem('status',JSON.stringify(response?.data)))
        .catch(err => console.log(err))
}
export const postApiResponse = async(url,data, setState) => {
    setState(prev=>{return{...prev, loading:true}})
    await axios.post(`${baseUrl}${url}`, data, {
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
        }})
        .then(response => setState({success:true, error:false, data:response, loading:false}))
        .catch(err => setState({success:false, error:true, data:err.response, loading:false}))
}
export const putApiResponse = async(url,data, setState) => {
    setState(prev=>{return{...prev, loading:true}})
    await axios.put(`${baseUrl}${url}`, data, {
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
        }})
        .then(response => setState({success:true, error:false, data:response, loading:false}))
        .catch(err => setState({success:false, error:true, data:err.response, loading:false}))
}
export const getApiResponseParse = async(url, setState) => {
    setState({success:false, error:false, data:null, loading:true})
    await axios.get(`${baseUrl}${url}`, {
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
        }})
        .then(response => setState({success:true, error:false, data:response?.data, loading:false}))
        .catch(err => setState({success:false, error:true, data:err.response, loading:false}))
}
export const getApiResponse = async(url, setState) => {
    setState(prev=>{return{...prev, loading:true}})
    await axios.get(`${baseUrl}${url}`, {
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
        }})
        .then(response => setState({success:true, error:false, data:response, loading:false}))
        .catch(err => setState({success:false, error:true, data:err.response, loading:false}))
}
export const getResponse = async(url, setState) => {
    await axios.get(`${baseUrl}${url}`, {
        headers: {
       "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json"
        }})
        .then(response => setState({success:response, error:''}))
        .catch(err => setState({success:'',error: err}))
}
export const postResponse = async(url, data, setState) => {
    await axios.post(`${baseUrl}${url}`, data, {
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
        }})
        .then(response => setState({success: response, error:''}))
        .catch(err => setState({success:'',error: err}))
}
export const putResponse = async(url, data, setState) => {
    await axios.put(`${baseUrl}${url}`, data, {
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
        }})
        .then(response => setState({success: response, error:''}))
        .catch(err => setState({success:'',error: err}))
}