const initialState = {
    lang:'uz'
}

const changeLangReducer = (state=initialState, action) => {
    switch(action.type){
        case 'CHANGE_LANG':return {...state, lang:action.payload}
        default: return state
    }
}
export default changeLangReducer