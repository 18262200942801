import {
    USER_SET,
    USER_REMOVE,
    SET_ORDER_BASKET,
} from '../actionTypes';

export const userSet = (payload) => {
    return {
        type: USER_SET,
        payload,
    };
};

export const userRemove = () => {
    return {
        type: USER_REMOVE,
    };
};

export const setOrderBasket = (payload) => {
    return {
        type: SET_ORDER_BASKET,
        payload,
    };
};