import {useDispatch, useSelector} from "react-redux"



export default function TranslateColumn(name, translate) {

    const ln = useSelector(state => state.changeLangReducer)
    // console.log()
    const columns = {
        call: [
            { id: 'id', label: 'ID', numeric: true, disablePadding: false },
            { id: 'phone_number', label: 'Phone Number', numeric: false, disablePadding: false },
            { id: 'first_name', label: 'First Name', numeric: false, disablePadding: false },
            { id: 'last_name', label: 'Last Name', numeric: false, disablePadding: false },
            // { id: 'type', label: 'Type', numeric: false, disablePadding: false },
            // { id: 'status', label: 'Status', numeric: false, disablePadding: false },
            { id: 'orders', label: 'Orders', type: 'button', numeric: false, disablePadding: false },
            // { id: 'customer', label: 'Customer', numeric: false, disablePadding: false },
        ],
        calculate: [
            { id: 'id', label: 'ID', numeric: true, disablePadding: false },
            { id: 'name', label: 'Name', numeric: false, disablePadding: false },
            { id: 'quantity', label: 'Amount', numeric: false, disablePadding: false },
            { id: 'price', label: 'Per product', numeric: false, disablePadding: false },
            { id: 'calc_total', label: 'Price total', numeric: false, disablePadding: false },
        ],
        customer: [
            { id: 'id', label: 'ID', numeric: true, disablePadding: false },
            { id: 'first_name', label: 'First Name', numeric: false, disablePadding: false },
            { id: 'last_name', label: 'Last Name', numeric: false, disablePadding: false },
            { id: 'phone', label: 'Phone', numeric: false, disablePadding: false },
            { id: 'email', label: 'Email', numeric: false, disablePadding: false },
        ],
    
        branch: [
            { id: 'id', label: 'ID', numeric: true, disablePadding: false },
            { id: 'name', label: 'Name', numeric: false, disablePadding: false },
            { id: 'branch_Type', label: 'Branch type', numeric: false, disablePadding: false },
            { id: 'address', label: 'Adress', numeric: false, disablePadding: false },
            { id: 'phone', label: 'Phone', numeric: false, disablePadding: false },
            { id: 'lat', label: 'Latitude', numeric: false, disablePadding: false },
            { id: 'lng', label: 'Longitude', numeric: false, disablePadding: false },
        ],
    
        order: [
            // { id: 'status', label: 'Status', numeric: false, disablePadding: false },
            // { id: 'shipping_address', label: 'Adress', numeric: false, disablePadding: false },
            { id: 'id', label: 'ID', numeric: true, disablePadding: false },
            { id: 'order_type', label: 'Order Type', numeric: false, disablePadding: false },
            { id: 'subtotal', label: 'Total Cost', numeric: true, disablePadding: false },
        ],
    
        history: [
            { id: 'main_order_id', label: 'ID', numeric: true, disablePadding: false },
            { id: 'session', label: 'Session', numeric: true, disablePadding: false },
            { id: 'created_date', label: 'Created Date', numeric: true, disablePadding: false },
            { id: 'phoneNumber', label: 'Phone', numeric: false, disablePadding: false },
            { id: 'order_type', label: 'Order Type', numeric: false, disablePadding: false },
            { id: 'payment_method', label: 'Payment Method', numeric: false, disablePadding: false },
            { id: 'subtotal', label: 'Total Cost', numeric: true, disablePadding: false },
        ],
    
        product: [
            { id: 'id', label: 'ID', numeric: true, disablePadding: false },
            { id: `name_${ln.lang}`, label: 'Name', numeric: false, disablePadding: false },
            { id: 'price', label: 'Price', numeric: false, disablePadding: false },
            { id: 'retail_price', label: 'Retail Price', numeric: false, disablePadding: false },
        ],
        productRecipe: [
            { id: 'id', label: 'ID', numeric: true, disablePadding: false },
            { id: `name_${ln.lang}`, label: 'Name', numeric: false, disablePadding: false },
            // { id: `currency`, label: 'Currency', numeric: false, disablePadding: false },
            // { id: `category`, label: 'Category', numeric: false, disablePadding: false },
            { id: 'price', label: 'Price', numeric: false, disablePadding: false },
            { id: 'retail_price', label: 'Retail Price', numeric: false, disablePadding: false },
        ],
    
        user: [
            { id: 'id', label: 'ID', numeric: true, disablePadding: false },
            { id: 'first_name', label: 'First Name', numeric: false, disablePadding: false },
            { id: 'last_name', label: 'Last Name', numeric: false, disablePadding: false },
            { id: 'phone', label: 'Phone', numeric: false, disablePadding: false },
            { id: 'role', label: 'Role', numeric: false, disablePadding: false },
            { id: 'select', label: 'Select', type: 'button', numeric: false, disablePadding: false },
        ],
        userHistory: [
            { id: 'id', label: 'Order Id', numeric: true, disablePadding: false },
            { id: 'created_date', label: 'Created Date', numeric: false, disablePadding: false },
            { id: 'subtotal', label: 'Subtotal', numeric: false, disablePadding: false },
            { id: 'shipping_address_txt', label: 'Address', numeric: false, disablePadding: false },
            { id: 'branch_id', label: 'Branch', numeric: false, disablePadding: false },
            { id: 'select', label: 'Select', type: 'button', numeric: false, disablePadding: false },
        ],
    
        sms: [
            { id: 'id', label: 'ID', numeric: true, disablePadding: false },
            { id: 'phone_number', label: 'Phone Number', numeric: true, disablePadding: false },
            { id: 'text', label: 'Text', numeric: false, disablePadding: false },
            { id: 'status', label: 'Status', numeric: false, disablePadding: false },
            { id: 'assign', label: 'Assign', numeric: true, disablePadding: false },
            { id: 'user', label: 'User', numeric: true, disablePadding: false },
        ],
    
        category: [
            { id: 'id', label: 'ID', numeric: true, disablePadding: false },
            { id: `name_${ln.lang}`, label: 'Name', numeric: false, disablePadding: false },
            { id: 'order_in_list', label: 'Order in List', numeric: true, disablePadding: false },
            { id: `description_${ln.lang}`, label: 'Description', numeric: false, disablePadding: false },
        ],
        vendor: [
            { id: 'id', label: 'ID', numeric: true, disablePadding: false },
            { id: 'name', label: 'Name', numeric: false, disablePadding: false },
            { id: 'description', label: 'Description', numeric: false, disablePadding: false },
        ],
        balance_of_goods: [
            { id: 'id', label: 'ID', numeric: true, disablePadding: false, backgroundColor:"#343c4b", color:"#AFBDD1"},
            { id: 'name', label: 'Name', numeric: false, disablePadding: false,  backgroundColor:"#343c4b", color:"#AFBDD1"},
            { id: 'description', label: 'Description', numeric: false, disablePadding: false,  backgroundColor:"#343c4b", color:"#AFBDD1"},
        ],
    
        campaign: [
            { id: 'id', label: 'ID', numeric: true, disablePadding: false },
            { id: 'name', label: 'Name', numeric: false, disablePadding: false },
            { id: 'status', label: 'Status', numeric: false, disablePadding: false },
        ],
        tariff: [
            { id: 'id', label: 'ID', numeric: true, disablePadding: false },
            { id: 'name', label: 'Name', numeric: false, disablePadding: false },
            { id: 'min_price', label: 'Price', numeric: false, disablePadding: false },
            { id: 'select', label: '', numeric: false, type: 'button', disablePadding: false },
        ],
        survey: [
            { id: 'id', label: 'ID', numeric: true, disablePadding: false },
            { id: `title_${ln.lang}`, label: 'Savollar', numeric: false, disablePadding: false },
        
        ],
        modification: [
            { id: 'id', label: 'ID', numeric: true, disablePadding: false },
            { id: 'name', label: 'Savollar', numeric: false, disablePadding: false },
            { id: 'created_date', label: 'Yaratilgan sanasi', numeric: false, disablePadding: false },
        ],
        notification: [
            { id: 'id', label: 'ID', numeric: true, disablePadding: false },
            { id: 'send_time', label: 'Send time', numeric: false, disablePadding: false },
            { id: 'text', label: 'Text', numeric: false, disablePadding: false },
            { id: 'status', label: 'Status', numeric: false, disablePadding: false },
        ],
        goods : [
            { id: 'id', label: 'ID', numeric: true, disablePadding: false },
            { id: `name_${ln.lang}`, label: 'Nomi', numeric: false, disablePadding: false },
            { id: `description_${ln.lang}`, label: 'Tarif', numeric: false, disablePadding: false },
            { id: 'category', label: 'Goods category', numeric: false, disablePadding: false },
            { id: 'vendor', label: 'Yetkazib beruvchi', numeric: false, disablePadding: false },
        ],
        goods_analog : [
            { id: 'id', label: 'ID', numeric: true, disablePadding: false },
            { id:'name', label: 'Nomi', numeric: false, disablePadding: false },
            { id: 'branch', label: 'Filial', numeric: false, disablePadding: false },
            { id: 'description', label: 'Tarif', numeric: false, disablePadding: false },
            { id: 'category', label: 'Goods category', numeric: false, disablePadding: false },
            { id: 'vendor', label: 'Yetkazib beruvchi', numeric: false, disablePadding: false },
        ],
        goods_pr : [
            { id: 'id', label: 'ID', numeric: true, disablePadding: false },
            { id: `name_${ln.lang}`, label: 'Nomi', numeric: false, disablePadding: false },
            { id: `sum`, label: 'Tarif', numeric: false, disablePadding: false },
            { id: 'currency', label: 'Goods category', numeric: false, disablePadding: false },
            { id: 'quantity', label: 'Yetkazib beruvchi', numeric: false, disablePadding: false },
            { id: 'act', label: 'Yetkazib beruvchi', numeric: false, disablePadding: false },
        ],
        goods_category : [
            { id: 'id', label: 'ID', numeric: true, disablePadding: false },
            { id: `name_${ln.lang}`, label: 'Nomi', numeric: false, disablePadding: false },
            { id: 'created_date', label: 'Created Date', numeric: false, disablePadding: false },
        ],
        goods:[
            { id: 'id', label: 'ID', numeric: true, disablePadding: false },
            { id: `name`, label: 'Nomi', numeric: false, disablePadding: false },
            { id: `description`, label: 'Tarif', numeric: false, disablePadding: false },
            { id: `category`, label: 'Goods category', numeric: false, disablePadding: false },
            { id: `vendor`, label: 'Yetkazib beruvchi', numeric: false, disablePadding: false },
            { id: 'price', label: 'Price', numeric: false, disablePadding: false },
        ],
        unit_type:[
            { id: 'id', label: 'ID', numeric: true, disablePadding: false },
            { id: `name_${ln.lang}`, label: 'Name', numeric: false, disablePadding: false },
            { id: `parent_${ln.lang}`, label: 'Type', numeric: false, disablePadding: false },
            { id: `value_${ln.lang}`, label: 'Value', numeric: false, disablePadding: false },
        ],
        session:[
            { id: 'id', label: 'ID', numeric: true, disablePadding: false },
            { id: 'name', label: 'Nomi', numeric: false, disablePadding: false },
            { id: 'note', label: 'Izoh', numeric: false, disablePadding: false },
            { id: 'available_funds', label: 'Funds', numeric: false, disablePadding: false },
            { id: 'total_sum', label: 'To\'liq mablag\'', numeric: false, disablePadding: false },
            { id: 'user', label: 'Foydalanuchi', numeric: false, disablePadding: false },
            { id: 'main_id', label: 'Main id', numeric: false, disablePadding: false },
        ],
        income:[
            { id: 'id', label: 'ID', numeric: true, disablePadding: false },
            // { id: 'income_date', label: 'Sana', numeric: false, disablePadding: false },
            { id: 'created_date', label: 'Created Date', numeric: true, disablePadding: false },
            { id: 'vendor', label: 'Yetkazib beruvchi', numeric: false, disablePadding: false },
            { id: 'branch', label: 'Filial', numeric: false, disablePadding: false },
            { id: 'total_sum', label: 'jami Summa', numeric: true, disablePadding: false },
            // { id: 'expired_date', numeric: false, disablePadding: false, label: 'Expired Date' },
            { id: 'name', label: 'Nomi', numeric: false, disablePadding: false },
        ],
        revision:[
            { id: 'id', label: 'ID', numeric: true, disablePadding: false },
            { id: 'name', label: 'Nomi', numeric: false, disablePadding: false },
            { id: 'revision_date', label: 'Sana', numeric: false, disablePadding: false },
            { id: 'branch', label: 'Filial', numeric: false, disablePadding: false },
            { id: 'total_sum', label: 'jami Summa', numeric: true, disablePadding: false },
     
        ],
        incomeTable:[
            { id: 'name', numeric: false, disablePadding: true, label: 'Name' },
            { id: 'expired_date', numeric: false, disablePadding: false, label: 'Expired Date' },
            { id: 'description', numeric: false, disablePadding: false, label: 'Description' },
            { id: 'quantity', numeric: true, disablePadding: false, label: 'Quantity' },
            { id: 'price', numeric: true, disablePadding: false, label: 'Price' },
            { id: 'sum', numeric: true, disablePadding: false, label: 'Sum' },
        
        ],
        revisionTable:[
            { id: 'name', numeric: false, disablePadding: true, label: 'Name' },
            { id: 'description', numeric: false, disablePadding: false, label: 'Description' },
            { id: 'quantity', numeric: true, disablePadding: false, label: 'Quantity' }
        ]

    };
    


    return columns[name].map((el, i) => {
        // Skipping translation if 'ID'
        if (i > 0) return { ...el, label: translate(`${name}Props.${el.id}`) };
        else return el;
    });
}
