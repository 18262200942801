import React, { useEffect } from 'react';
import {
    Button,
    TextField,
    Grid,
    FormControlLabel,
    Checkbox,
    makeStyles,
    Divider,
} from '@material-ui/core';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import defProduct from '../../../../assets/images/defProduct.webp';
import { useSelector } from 'react-redux';
import { VALUES, DARKVALUES, GREENVALUES } from '../../../../constants/styleConstants';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { categoryGet } from '../../../../utils/apiRequest';

export default function ProductsForm(props) {
    const theme = useSelector((state) => state.theme);
    let colorValues;
    if (theme.name === 'dark') {
        colorValues = VALUES;
    } else if (theme.name === 'green') {
        colorValues = GREENVALUES;
    } else {
        colorValues = DARKVALUES;
    }
    //
    const useStyles = makeStyles((theme) => ({
        imageBox: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        image: {
            height: '140px',
            width: '180px',
            marginBottom: '10px',
            border: '1px solid black',
            borderRadius: '5px',
            objectFit: 'contain',
        },
        uploadBtn: {
            padding: '4px',
        },
        root: {
            padding: '8px',
        },
        divider: {
            margin: '20px 0',
        },
        rightContainer: {
            display: 'flex',
            flexDirection: 'column',
        },

        //
        colorPrimary: {
            color: colorValues.colorPrimary,
            background: colorValues.colorBgPrimary,
        },
    }));
    //
    const classes = useStyles();
    const { t } = useTranslation();

    const [categories, setCategories] = React.useState(null);

    useEffect(() => {
        categoryGet().then((res) => setCategories(res.data.results));
    }, []);

    return (
        <>
            <Grid container justify="space-around" alignItems="center">
                <Grid item sm={3} className={classes.imageBox}>
                    {props.input.photo?.image === undefined ? (
                        <img
                            id="productUpload"
                            className={classes.image}
                            src={defProduct}
                            alt="Product Def"
                        />
                    ) : (
                        <img
                            id="productUpload"
                            className={classes.image}
                            src={props.input.photo.image}
                            alt="Product"
                        />
                    )}
                    <Button
                        className={classes.uploadBtn}
                        variant="contained"
                        color="primary"
                        component="label"
                    >
                        {t('productProps.upload_photo')}
                        <input
                            type="file"
                            hidden
                            accept="image/*"
                            onChange={props.handleFileChange('image')}
                        />
                    </Button>
                </Grid>
                <Grid item sm={3}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        id="vendor"
                        label={t('productProps.vendor')}
                        required
                        type="number"
                        value={props.input['vendor'] || ''}
                        onChange={props.handleTextChange('vendor')}
                        margin="dense"
                    />
                </Grid>
                <Grid item sm={3}>
                    <FormControl variant="outlined" fullWidth size="small" margin="dense">
                        <InputLabel id="demo-simple-select-outlined-label">Category</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={props.input['category'] || ''}
                            onChange={props.handleTextChange('category')}
                            label={t('productProps.category')}
                            required
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {categories?.map((result) => {
                                return (
                                    <MenuItem key={result.id} value={result.id}>
                                        {result.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item sm={3}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        id="order_in_list"
                        label={t('productProps.order_in_list')}
                        required
                        type="number"
                        value={props.input['order_in_list'] || ''}
                        onChange={props.handleTextChange('order_in_list')}
                        margin="dense"
                    />
                </Grid>
            </Grid>
            <Divider className={clsx(classes.divider, classes.colorPrimary)} />

            <Grid container justify="space-around">
                <Grid item sm={5}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        id="name"
                        label={t('productProps.name')}
                        type="text"
                        value={props.input['name'] || ''}
                        onChange={props.handleTextChange('name')}
                        margin="dense"
                    />
                </Grid>
                <Grid item sm={5} className={classes.rightContainer}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        id="name_ru"
                        label={t('productProps.name_ru')}
                        type="text"
                        value={props.input['name_ru'] || ''}
                        onChange={props.handleTextChange('name_ru')}
                        margin="dense"
                    />
                    <TextField
                        fullWidth
                        variant="outlined"
                        id="name_uz"
                        label={t('productProps.name_uz')}
                        type="text"
                        value={props.input['name_uz'] || ''}
                        onChange={props.handleTextChange('name_uz')}
                        margin="dense"
                    />
                    <TextField
                        fullWidth
                        variant="outlined"
                        id="name_en"
                        label={t('productProps.name_en')}
                        type="text"
                        value={props.input['name_en'] || ''}
                        onChange={props.handleTextChange('name_en')}
                        margin="dense"
                    />
                </Grid>
            </Grid>
            <Divider className={clsx(classes.divider, classes.colorPrimary)} />

            <Grid container justify="space-around">
                <Grid item sm={5}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        id="price"
                        label={t('productProps.price')}
                        type="number"
                        value={props.input['price'] || ''}
                        onChange={props.handleTextChange('price')}
                        margin="dense"
                    />
                </Grid>
                <Grid item sm={5} className={classes.rightContainer}>
                    <TextField
                        fullWidth
                        required
                        variant="outlined"
                        id="retail_price"
                        label={t('productProps.retail_price')}
                        type="number"
                        value={props.input['retail_price'] || ''}
                        onChange={props.handleTextChange('retail_price')}
                        margin="dense"
                    />
                    <TextField
                        fullWidth
                        variant="outlined"
                        id="actual_price"
                        label={t('productProps.actual_price')}
                        type="number"
                        value={props.input['actual_price'] || ''}
                        onChange={props.handleTextChange('actual_price')}
                        margin="dense"
                    />
                    <TextField
                        fullWidth
                        variant="outlined"
                        id="discount_price"
                        label={t('productProps.discount_price')}
                        type="number"
                        value={props.input['discount_price'] || ''}
                        onChange={props.handleTextChange('discount_price')}
                        margin="dense"
                    />
                </Grid>
            </Grid>
            <Divider className={clsx(classes.divider, classes.colorPrimary)} />

            <Grid container justify="space-around">
                <Grid item sm={5}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        id="description"
                        label={t('productProps.description')}
                        type="text"
                        value={props.input['description'] || ''}
                        onChange={props.handleTextChange('description')}
                        margin="dense"
                    />
                </Grid>
                <Grid item sm={5} className={classes.rightContainer}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        id="description_ru"
                        label={t('productProps.description_ru')}
                        type="text"
                        value={props.input['description_ru'] || ''}
                        onChange={props.handleTextChange('description_ru')}
                        margin="dense"
                    />
                    <TextField
                        fullWidth
                        variant="outlined"
                        id="description_uz"
                        label={t('productProps.description_uz')}
                        type="text"
                        value={props.input['description_uz'] || ''}
                        onChange={props.handleTextChange('description_uz')}
                        margin="dense"
                    />
                    <TextField
                        fullWidth
                        variant="outlined"
                        id="description_en"
                        label={t('productProps.description_en')}
                        type="text"
                        value={props.input['description_en'] || ''}
                        onChange={props.handleTextChange('description_en')}
                        margin="dense"
                    />
                </Grid>
            </Grid>
            <Divider className={clsx(classes.divider, classes.colorPrimary)} />

            <Grid container justify="space-around">
                <Grid item>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="is_published"
                                checked={props.input['is_published'] || false}
                                color="primary"
                                onChange={props.handleTextChange('is_published')}
                            />
                        }
                        label={t('productProps.is_published')}
                    />
                </Grid>
                <Grid item>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="is_cookmen"
                                checked={props.input['is_cookmen'] || false}
                                color="primary"
                                onChange={props.handleTextChange('is_cookmen')}
                            />
                        }
                        label={t('productProps.is_cookmen')}
                    />
                </Grid>
                <Grid item>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="is_new"
                                checked={props.input['is_new'] || false}
                                color="primary"
                                onChange={props.handleTextChange('is_new')}
                            />
                        }
                        label="New"
                    />
                </Grid>
                <Grid item>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="is_active"
                                checked={props.input['is_active'] || false}
                                color="primary"
                                onChange={props.handleTextChange('is_active')}
                            />
                        }
                        label={t('productProps.is_active')}
                    />
                </Grid>
                <Grid item>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="is_featured"
                                checked={props.input['is_featured'] || false}
                                color="primary"
                                onChange={props.handleTextChange('is_featured')}
                            />
                        }
                        label={t('productProps.is_featured')}
                    />
                </Grid>
            </Grid>
        </>
    );
}
