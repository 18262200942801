import {
    SELECTED_KANBAN,
    SELECTED_KANBAN_CLEAR,
    SELECTED_CALL_CLEAR,
    SELECTED_CALL,
    SELECTED_COURIER,
    SELECTED_COURIER_CLEAR,
    COURIER_DIALOG,
    CALL_DIALOG,
    TARIFF_DIALOG,
    SELECTED_TARIFF,
    USER_DIALOG,
    SELECTED_ADDRESS_TEXT, SELECTED_BRANCH
} from '../actionTypes';


const initialState = {
    selectedKanban: [],
    selectedPhoneNumber: '',
    selectedAddressText: '',
    selectedBranch: null,
    selectedCourier: '',
    selectedCourierName: '',
    selectedTariff: [],
    selectedTariffValue: '',
    courierDialogOpen: false,
    callDialogOpen: false,
    tariffDialogOpen: false,
    selectedDeliveryPrice: '',
    userDialogOpen: false,
    userId: null

};

const kanbanReducer = (state = initialState, action) => {
    switch (action.type) {

        case SELECTED_KANBAN:
            return {
                ...state,
                selectedKanban: action.payload
            };
        case SELECTED_KANBAN_CLEAR:
            return {
                ...state,
                selectedKanban: []
            };
        case SELECTED_CALL:
            return {
                ...state,
                selectedPhoneNumber: action.payload
            };
        case SELECTED_ADDRESS_TEXT:
            return {
                ...state,
                selectedAddressText: action.payload
            };
        case SELECTED_BRANCH:
            return {
                ...state,
                selectedBranch: action.payload
            };
        case SELECTED_CALL_CLEAR:
            return {
                ...state,
                selectedPhoneNumber: ''
            };
        case SELECTED_COURIER:
            return {
                ...state,
                selectedCourier: action.payload,
                selectedCourierName: action.payload.first_name
            };
        case COURIER_DIALOG:
            return {
                ...state,
                courierDialogOpen: action.payload
            };
        case CALL_DIALOG:
            return {
                ...state,
                callDialogOpen: action.payload
            };
        case SELECTED_COURIER_CLEAR:
            return {
                ...state,
                selectedCourier: ''
            };
        case TARIFF_DIALOG:
            return {
                ...state,
                tariffDialogOpen: action.payload
            };
        case SELECTED_TARIFF:
            return {
                ...state,
                selectedTariff: action.payload,
                selectedDeliveryPrice: action.payload?.min_price
            };
        case USER_DIALOG:
            return {
                ...state,
                userDialogOpen: action.payload.dialog,
                userId: action.payload.id
            };
        default:
            return state;
    }
};

export default kanbanReducer;
