/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as cookies from '../utils/Cookies';
import Confirm from './Confirm';
import { refreshToken } from '../utils/apiRequest';

export default function ExtendSessionConfirm(props) {
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();

    const interval = setInterval(() => {
        const { jwt, session } = cookies.getAll();
        if (!session && jwt) {
            clearInterval(interval);
            setOpen(true);

            setTimeout(() => {
                if (open) {
                    setOpen(false);
                    window.location = '/logout';
                }
            }, 1000 * 20);
        }
    }, 5000);

    //
    function handleClose() {
        clearInterval(interval);

        cookies.logout();
        setOpen(false);
        window.location = '/login';
    }
    function handleConfirm() {
        clearInterval(interval);

        const refresh = cookies.get('refreshToken');
        if (!refresh) {
            cookies.logout();
            return;
        }

        refreshToken({ refresh })
            .then((res) => {
                cookies.refreshUser({ jwt: res.data.access });
                setOpen(false);
            })
            .catch((err) => console.error(err));
    }

    return (
        <Confirm
            open={open}
            header={t('extendSession.header')}
            text={t('extendSession.text')}
            handleClose={handleClose}
            handleConfirm={handleConfirm}
        />
    );
}
