import React, { memo } from 'react'
import Table from "./table.center"
import getColumns from '../../../../constants/tableColumnConstants';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
const TableOne = ({ addModal, handleDelete, loading, data, setPaging, setAddModal, firstClick, setTableThree, tableThree, stateModal }) => {
    const { t } = useTranslation();
    const columns = getColumns('goods_pr', t);
    return (
        <div className="wrap_table_2">
            <div className="header_goods_pr">
                <div className="title_19sp">{t('sidebar.goods')}</div>
                {
                    firstClick &&
                    <div>
                        <Fab color="primary" className="round" aria-label="add" onClick={() => setAddModal(true)}>
                            <AddIcon />
                        </Fab>
                    </div>
                }
            </div>
            <Table
                stateModal={stateModal}
                addModal={addModal}
                setTableThree={setTableThree}
                tableThree={tableThree}
                firstClick={firstClick}
                columns={columns}
                data={data?.map(item => { return { ...item, currency: item?.unit_type?.name, name: item?.good?.name } }) || []}
                loading={false}
                type="two"
                setPaging={setPaging}
                handleDelete={handleDelete}
            />
        </div>
    )
}

export default memo(TableOne)