import React, { memo, useRef } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useForm } from "react-hook-form"
import EmptyList from "../../components/empty.list"
import IconButton from "@material-ui/core/IconButton";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import * as cookies from '../../../../utils/Cookies';
import Button from '@mui/material/Button';
import CalculateIcon from '@mui/icons-material/Calculate';
import Fab from '@mui/material/Fab';
import { VALUES, DARKVALUES, GREENVALUES } from '../../../../constants/styleConstants';


const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // height: '100px',
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
    '&:hover': {
        backgroundColor: '#ccc',
        cursor: 'pointer'
    },
}));

const CustomizedTables = ({ handleDelete, loading,stateModal, columns, data, type, setFirstClick, firstClick, setTableThree, tableThree }) => {
    const { register, handleSubmit, reset, watch } = useForm()
    const inputElement = useRef(null)
    const theme = useSelector((state) => state.theme);
    const lang = cookies.get('lang')
    let colorValues;
    if (theme.name === 'dark') {
        colorValues = VALUES;
    } else if (theme.name === 'green') {
        colorValues = GREENVALUES;
    } else {
        colorValues = DARKVALUES;
    }
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: colorValues.colorBgPrimary,
            color: colorValues.colorPrimary,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 12,
        },
    }));
    const { t } = useTranslation();
    const [state, setState] = React.useState([])
    React.useEffect(() => {
        if (data) {
            setState(data)
        }
    }, [data])
    console.log(state, stateModal)

    React.useEffect(() => {
        if (firstClick)
            reset({})
   
    }, [firstClick])
    
    const onSubmit = (data) => {
        let list = Object.keys(data)?.filter(prev => prev.startsWith(`${firstClick?.id}`))
        let cloneList = list?.map(item => item.slice(item.indexOf('_') + 1))
        let cloneState = state
        for (var i = 0; i < cloneState.length; i++) {
            for (var j = 0; j < cloneList.length; j++) {
                if (cloneState[i]?.good_id === parseInt(cloneList[j])) {
                    cloneState[i].calc_total = parseInt(data[`${firstClick.id}_${cloneList[j]}`]) * parseInt(cloneState[i]?.price)
                    cloneState[i].quantity = data[`${firstClick.id}_${cloneList[j]}`]
                }
                if (cloneState[i]?.good?.id === parseInt(cloneList[j])) {
                    cloneState[i].good_id = cloneState[i]?.good?.id
                    cloneState[i].calc_total = parseInt(data[`${firstClick.id}_${cloneList[j]}`]) * parseInt(cloneState[i]?.price)
                    cloneState[i].quantity = data[`${firstClick.id}_${cloneList[j]}`]
                }
            }
        }
        setTableThree(cloneState)
    }

    return (
        <form ref={inputElement} onSubmit={handleSubmit(onSubmit)}>
            {firstClick && 
              <Fab variant="extended" color="primary" size="small" className="bton_ppr" aria-label="add" type="submit">
                <CalculateIcon />{t('goods_prProps.calc')}
              </Fab>}
                {/* // <Button variant="contained" className="noprvc second" type="submit" >{t('goods_prProps.calc')}</Button>} */}
            <TableContainer component={Paper} style={{ height: '80vh', overflowY: 'scroll' }}>
                <Table stickyHeader className="table_body_20" sx={type === 'one' ? { minWidth: 490 } : type === 'two' ? { minWidth: 380 } : { minWidth: 420 }} aria-label="customized table">
                    <TableHead>
                        <TableRow style={{ 'backgroundColor': '#f5f5f5', "height": '35px' }}>
                            {columns?.map(item => (<StyledTableCell key={item.id}>{item.label}</StyledTableCell>))}
                        </TableRow>
                    </TableHead>
                    {
                        loading ?
                            <TableBody>
                                <TableCell colSpan={12} style={{ textAlign: 'center' }}>
                                    <div>loading...</div>
                                </TableCell>
                            </TableBody> :
                            state?.length < 1 ?
                                <TableBody>
                                    <TableCell colSpan={12} style={{ textAlign: 'center' }}>
                                        <EmptyList height="400px" top="100px" />
                                    </TableCell>
                                </TableBody> :
                                <TableBody>
                                    {state && state?.filter(prev => prev?.new === true)?.map((row, index) => (
                                        <StyledTableRow style={firstClick?.id === row?.id ? { background: '#ccc' } : null} key={index} onClick={() => type === 'one' ? setFirstClick(row) : null}>
                                            <StyledTableCell component="th" scope="row">{row?.id}</StyledTableCell>
                                            <>
                                                <StyledTableCell component="th" scope="row">{row[`name_${lang}`] || t('profile.nodata')}</StyledTableCell>
                                                <StyledTableCell component="th" scope="row">{row['price'] || t('profile.nodata')}</StyledTableCell>
                                                <StyledTableCell component="th" scope="row">{row['currency'] || t('profile.nodata')}</StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <input
                                                        className="number_inp_123"
                                                        type="number"
                                                        defaultValue={0}
                                                        style={{
                                                            width: '90px',
                                                            padding: '4px 2px'
                                                        }}
                                                        {...register(`${firstClick.id}_${row.good_id}`)}
                                                    />
                                                </StyledTableCell>
                                            </>
                                            <StyledTableCell component="th" scope="row">
                                                <IconButton onClick={() => handleDelete(row?.id)}><HighlightOffIcon /></IconButton>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                    {
                                        state && state?.filter(prev => prev.hasOwnProperty('new') === false)?.map((row, index) => (
                                            <StyledTableRow style={firstClick?.id === row?.id ? { background: '#ccc' } : null} key={index} onClick={() => type === 'one' ? setFirstClick(row) : null}>
                                                <StyledTableCell component="th" scope="row">{row?.good?.id}</StyledTableCell>
                                                <>
                                                    <StyledTableCell component="th" scope="row">{row[`name_${lang}`] || t('profile.nodata')}</StyledTableCell>
                                                    <StyledTableCell component="th" scope="row">{row['price'] || t('profile.nodata')}</StyledTableCell>
                                                    <StyledTableCell component="th" scope="row">{row['currency'] || t('profile.nodata')}</StyledTableCell>
                                                    <StyledTableCell component="th" scope="row">
                                                        <input
                                                            className="number_inp_123"
                                                            type="number"
                                                            defaultValue={parseInt(row?.quantity)?.toFixed() || 0}
                                                            style={{
                                                                width: '90px',
                                                                padding: '4px 2px'
                                                            }}
                                                            {...register(`${firstClick.id}_${row?.good?.id}`)}
                                                        />
                                                    </StyledTableCell>
                                                </>
                                                <StyledTableCell component="th" scope="row">
                                                    <IconButton onClick={() => handleDelete(row?.id)}><HighlightOffIcon /></IconButton>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))
                                    }
                                </TableBody>
                    }

                </Table>
            </TableContainer>

        </form>
    );
}
export default memo(CustomizedTables)