const VALUES = {
    sideBarWidthExpand: '240px',

    // colorPrimary: 'white',
    // colorBgPrimary: '#3D4977',
    colorPrimary: '#AFBDD1',
    colorBgPrimary: '#343c4b',
    colorHeader:'#afbdd1',
    colorMenu:'#afbdd1',

    colorSecondary: '#1c1c73',
    colorBgSecondary: '#FFF',

    colorAlternative: 'white',
    colorBgAlternative: '#E5E5E5',

    colorSidebarSelected: '#454e5f',
};
const DARKVALUES = {
    sideBarWidthExpand: '240px',

    // colorPrimary: 'white',
    // colorBgPrimary: '#3D4977',
    colorPrimary:'#343c4b',
    colorBgPrimary: '#E5E5E5',
    colorHeader:'#343c4b',
    colorMenu:'#343c4b',

    colorSecondary: '#343c4b',
    colorBgSecondary: '#FFF',

    colorAlternative: 'dark',
    colorBgAlternative: '#343c4b',

    colorSidebarSelected: '#f9f9f9',
};
const GREENVALUES = {
    sideBarWidthExpand: '240px',

    // colorPrimary: 'white',
    colorPrimary:'#fff',
    colorBgPrimary: '#1ca39d',
    colorHeader:'#fff',
colorMenu:'#1ca39d',
    

    colorSecondary: '#1ca39d',
    colorBgSecondary: '#FFF',

    colorAlternative: 'dark',
    colorBgAlternative: '#343c4b',

    colorSidebarSelected: '#f9f9f9',
};

export {VALUES, DARKVALUES, GREENVALUES};
