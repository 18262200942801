import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import getColumns from '../../../constants/tableColumnConstants';
import { courierGet,
    orderHistoryGet, orderHistoryTotalGet,
    productDelete, userGet,sessionGet
} from '../../../utils/apiRequest';
import Table from '../components/TableViewHistory';
import './OrdersHistory.css';
import {
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from '@material-ui/core';
import { orderPropList as propList } from '../../../constants/propListConstants';
import { useDispatch, useSelector } from 'react-redux';
import {  courierDialog,  } from '../../../redux/actions/KanbanActions';
import DateRangePicker from '@mui/lab/DateRangePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Box from '@mui/material/Box';
import { Autocomplete } from '@mui/material';
import NumberFormat from 'react-number-format';
import {getResponse} from "../../../requests/getApi"
import { ArrowRightIcon } from '@material-ui/pickers/_shared/icons/ArrowRightIcon';

const OrdersHistory = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const columns = getColumns('history', t);

    const [data, setData] = useState([1]);
    console.log(data, "all data filtered")
    const [couriers, setCouriers] = useState([]);

    const [count, setCount] = useState(0);
    const [, setCountCouriers] = useState(0);

    const [, setFormOpen] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [pagesToFetch, setPagesToFetch] = useState([]);
    const [pagesToFetchCouriers, setPagesToFetchCouriers] = useState([1]);
    const [waiting, setWaiting] = useState(false);
    const [alert, setAlert] = useState({ open: false, severity: '', message: '' });
    const [branchItems, setBranchItems] = useState({success:'', error:''})
    const [main_order_id, setMainOrderId] = useState(null);

    console.log(branchItems, "branch itemssssss-")
    const [valueDate, setValueDate] = React.useState([null, null]);
    const cash_local = {
        CASH:t('paymentType.CASH'),
    }
    const order_local = {
        weself:t('orderType.weself'),
        delivery:t('orderType.delivery'),
        inplace:t('orderType.inplace'),
        telegramdelivery:t('orderType.telegramdelivery'),
    }
    const kanban = (state) => state.kanban;
    const kanbanData = useSelector(kanban);

    const [formProps, setFormProp] = useState({
        status: null
    });
    // console.log(formProps, "form prps----")

    const handleSelectChange = (e) => {
        setFormProp({
            ...formProps,
            [e.target.name]: e.target.value
        });
    };

    const [resultUsers, setResultUsers] = useState([]);

    const [, setSelectValue] = React.useState();

    const handleTextChange = (e) => {
        userGet(1, 20, { search: e.target.value }).then(res => {
            setResultUsers(res.data.results);
        });
    };
     const [selectSession, setSelectSession] =useState([]);
    const handleSessionChange = (e) => {
        sessionGet(1, 20, { search: e.target.value }).then(res => {
            setSelectSession(res.data.results);
        });
    };

  console.log(main_order_id, "main-order-id")
    const handleIdChange = (e) => {
        orderHistoryGet(1, 30, { main_order_id: e.target.value }).then(res => {
            setMainOrderId(res.data.results);
        });
    }

    const [date, setDate] = React.useState(new Date());

    useEffect(() => {
        setWaiting(true);

        const prms = [];
        for (let i of pagesToFetch) prms.push(orderHistoryGet(i));

        Promise.all(prms)
            .then((res) => {
                const obj = [];
                res.forEach((el) => {
                    obj.push(...el.data.results);
                });
                let clone = obj.map((prev)=> {
                    return{
                        ...prev,
                        payment_method:cash_local[prev?.payment_method],
                        order_type:order_local[prev?.order_type],

                    }
                })
                setCount(res[0].data.count);
            })
            .catch((err) => {
                console.error(err);
                setData([]);
            })
            .finally(() => setWaiting(false));
    }, [pagesToFetch]);

    useEffect(() => {
        let clone = data.map((prev)=> {
            return{
                ...prev,
                payment_method:cash_local[prev?.payment_method],
                order_type:order_local[prev?.order_type],
            }
        })
        setData([...data, ...clone]);
        setRefresh(!refresh)
    },[localStorage.getItem('i18nextLng')])

    useEffect(() => {
        setWaiting(true);
        const prms = [];
        for (let i of pagesToFetchCouriers) prms.push(courierGet(i));

        Promise.all(prms)
            .then((res) => {
                const obj = [];
                res.forEach((el) => {
                    obj.push(...el.data.results);
                });

                setCouriers([...couriers, ...obj]);
                setCountCouriers(res[0].data.count);
            })
            .catch((err) => {
                console.error(err);
                setCouriers([]);
            })
            .finally(() => setWaiting(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, pagesToFetchCouriers);

    useEffect(() => {
        setData([]);
        setPagesToFetch([1]);
        setPagesToFetchCouriers([1]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refresh]);

    const handleClose = () => {
        dispatch(courierDialog(false));
    };

    //Courier  Select
    const [courierNames, setCourierNames] = useState([]);
    
    useEffect(()=>{ getResponse('/branches/?page=1&page_size=10',
            setBranchItems)},
        [refresh])

    if (!courierNames?.length && couriers?.length > 0) {
        couriers.map((courier) => {
            const { id, first_name, last_name } = courier;
            return (
                courierNames.push({
                    value: id,
                    label: first_name + ' ' + last_name
                })
            );
        });
    }
    const [total, setTotal] = React.useState(null);

    function submit(e) {
        e.preventDefault();

        const objToSend = {
            ...formProps,
        };
        if (valueDate[0] !== null) objToSend.from_date = valueDate[0];
        if (valueDate[1] !== null) objToSend.to_date = valueDate[1];
       console.log(objToSend, "object to send----")
        // console.log(objToSend);

        orderHistoryGet(1, 20, objToSend)
            .then((res) => {
                if (res.status >= 200 && res.status < 300) {
                    // window.location.reload(false);
                    // refreshPage();
                    // console.log(res.data.results, "hammasi chiqdi")
                    setData(res.data.results);
                    setCount(res.data.count);
                }
            })
            .catch((err) => {
                console.error(err);
                setData([]);
            });
        //total
        orderHistoryTotalGet(1, 20, objToSend)
            .then((res) => {
                if (res.status >= 200 && res.status < 300) {
                    console.log(res?.data?.subtotal__sum, "total summ")
                    setTotal(res.data.subtotal__sum);
                }
            })
            .catch((err) => {
                console.error(err);
                setTotal(null);
            });
    }

    return (
        <>
            <div className="filter-card">
                <form id="order-form" style={{ width: '100%' }}
                      onSubmit={submit}
                >
                    <Typography variant="h6">
                        {t('propList.order')}
                        {/* Order Filter */}
                    </Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                            <FormControl
                                style={{ width: '100%' }}
                                size="small"
                                // required
                            >
                                <InputLabel>{t('orderProps.order_type')}</InputLabel>
                                <Select
                                    name="order_type"
                                    value={formProps['order_type'] || ''}
                                    onChange={handleSelectChange}
                                >
                                    {
                                        propList[1].enum.map((el) => (
                                            <MenuItem value={el} key={el}>
                                                {t(`orderProps.${el}`)}
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={3}>
                            <FormControl
                                style={{ width: '100%' }}
                                size="small"
                                // required
                            >
                                <InputLabel>{t('orderProps.payment_method')}</InputLabel>
                                <Select
                                    name="payment_method"
                                    value={formProps['payment_method'] || ''}
                                    onChange={handleSelectChange}
                                >
                                    {
                                        propList[2].enum.map((el) => (
                                            <MenuItem value={el} key={el}>
                                                {t(`orderProps.${el}`)}
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={3}>
                            <FormControl
                                style={{ width: '100%' }}
                                size="small"
                                // required
                            >
                                <InputLabel>{t('orderProps.branch')}</InputLabel>
                                <Select
                                    name="branch"
                                    value={formProps['branch'] || ''}
                                    onChange={handleSelectChange}
                                >
                                    {
                                        branchItems?.success?.data?.results.map((prev)=>(
                                            <MenuItem value={prev?.id} key={prev?.id}>
                                                {prev?.name}
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={3}>
                            <FormControl
                                style={{ width: '100%' }}
                                size="small"
                                // required
                            >
                                <InputLabel>{t('orderProps.shipping_address.assign')}</InputLabel>
                                <Select
                                    name="courier"
                                    value={formProps['courier'] || ''}
                                    onChange={handleSelectChange}
                                >
                                    {
                                        courierNames.map((el) => (
                                            <MenuItem value={el.value} key={el.value}>
                                                {el.label}
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl
                                style={{ width: '100%' }}
                                size="small"
                            >
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DateRangePicker
                                        startText={t('orderProps.dateFrom')}
                                        endText={t('orderProps.dateTo')}
                                        value={valueDate}
                                        onChange={(newValue) => {
                                            setValueDate(newValue);
                                        }}
                                        renderInput={(startProps, endProps) => (
                                            <React.Fragment>
                                                <TextField size="small" {...startProps} />
                                                <Box sx={{ mx: 2 }}> <ArrowRightIcon/> </Box>
                                                <TextField size="small" {...endProps} />
                                            </React.Fragment>
                                        )}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl
                                style={{ width: '100%' }}
                                size="small"
                                // required
                            >
                                <Autocomplete
                                    onChange={(event, newValue) => {
                                        setSelectValue(newValue?.id);
                                    }}
                                    disablePortal
                                    id="combo-box-demo"
                                    options={resultUsers}
                                    getOptionLabel={(option) => option?.first_name + ' ' + option?.last_name + ' ' + option?.phone}
                                    renderInput={(params) => {
                                        return (
                                            <TextField
                                                {...params}
                                                size="small"
                                                label={t('orderProps.fullNamePhone')}
                                                type="text"
                                                name="search"
                                                onChange={handleTextChange}
                                            />
                                        );
                                    }}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={2}>
                            <TextField
                                style={{ width: '100%' }}
                                type="number"
                                name='main_order_id'
                                label="ID"
                                onChange={handleIdChange}
                            />
                        </Grid>
                        <Grid item xs={3}>
                                <FormControl
                                    style={{ width: '100%' }}
                                    size="small"
                                // required
                                >
                                    <Autocomplete
                                        // value={selectValue}
                                        onChange={(event, newValue) => {
                                            setSelectSession(newValue?.id);
                                        }}
                                        disablePortal
                                        id="combo-box-demo"
                                        options={selectSession}
                                        getOptionLabel={(option) =>option?.name}

                                        // sx={{ width: 300 }}
                                        renderInput={(params) => {
                                            return (
                                                <TextField
                                                    {...params}
                                                    size="small"
                                                    label={t('orderProps.session')}
                                                    type="text"
                                                    name="search"
                                                    onChange={handleSessionChange}
                                                />
                                            );
                                        }}
                                    />
                                </FormControl>
                        </Grid>

                        <Grid item xs={3}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography variant="h6" style={{ marginTop: '0.5rem' }}>
                                    {/*{t('propList.order')}*/}
                                    {t('orderProps.totalSum')}: {total &&
                                <NumberFormat
                                    value={total}
                                    className="total"
                                    displayType={'text'}
                                    thousandSeparator={' '}
                                    suffix={' So\'m'}
                                    renderText={(value, props) => <div {...props}>{value}</div>}
                                />}
                                </Typography>
                            </div>
                        </Grid>

                        <Grid item xs={1}>
                            <div className="filter-btn" style={{ marginTop: '0.5rem' }}>
                                <Button color="inherit" className="filter-button" type="submit" form="order-form">
                                    <Typography style={{ fontSize: '1rem' }}>{t('formControl.filter')}</Typography>
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </form>
            </div>
            <Table
                rows={data}
                count={count}
                columns={columns}
                handleFormOpen={setFormOpen}
                waiting={waiting}
                refreshPage={() => {
                    setRefresh(!refresh);
                }}
                setPagesToFetch={setPagesToFetch}
                reqDelete={productDelete}
            />
        </>
    );
};

export default OrdersHistory;

