import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Table from '../components/TableView';
import FormController from '../components/classFormControll';
import CategoriesForm from './forms/Classification';
import { categoryPropList as propList } from '../../../constants/propListConstants';
import getColumns from '../../../constants/tableColumnConstants';
import {
    classificationPost,
    classificationPut,
    classificationDelete,
    classGet
} from '../../../utils/apiRequest';
import { TableCell } from '@material-ui/core';

export default function Customers() {
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [formOpen, setFormOpen] = useState(false);
    const [idParent, setIdParent] = useState();
    const [historyParent, setHistoryParent] = useState([1])
    const [tableId,] = useState()
    const [callback, setCallback] = useState(false)
    const [refresh, setRefresh] = useState(false);
    const [pagesToFetch, setPagesToFetch] = useState([]);
    const [waiting, setWaiting] = useState(false);
    const [parent, setParent] = useState([1])

    const { t } = useTranslation();
    const columns = getColumns('category', t);

    useEffect(() => {
        setWaiting(true);

        const prms = [];
        for (let i of pagesToFetch) prms.push(classGet(parent,i));

        Promise.all(prms)
            .then((res) => {
                const obj = [];
                res.forEach((el) => {
                    obj.push(...el.data.results);
                });
                console.log(res, "res-----")
                setData([...obj]);
                setCount(res[0].data.count);
            })
            .catch((err) => {
                console.error(err);
                setData([]);
            })
            .finally(() => setWaiting(false));
    }, [pagesToFetch, parent, callback]);

    useEffect(() => {
        setData([]);
        setPagesToFetch([1]);
    }, [refresh]);
    const handleClickBtn = (data) => {
        console.log(data)
        if(data){
            setParent(data?.id)
            setHistoryParent([...historyParent, data?.id])
        }
    }
    console.log(historyParent, "---------historyuparent")


    const handleClickbackBtn = () => {
            if(historyParent[historyParent.length - 2]){
            setParent(historyParent[historyParent.length - 2])
            let history = historyParent.pop()
            console.log(history)
        }
    }
    return (
        <>
            <Table
                rows={data}
                count={count}
                columns={columns}
                waiting={waiting}
                handleFormOpen={setFormOpen}
                reqDelete={classificationDelete}
                refreshPage={() => setRefresh(!refresh)}
                setPagesToFetch={setPagesToFetch}
                componentButton
                buttonCLick = {handleClickBtn}
                tableId={tableId}
                handleClickbackBtn={handleClickbackBtn}
                historyParent={historyParent}
                componentTableCell={<TableCell></TableCell>}
                emptyList={waiting ? false :data?.length>0 ? false:true}
            />
            <FormController
                open={formOpen}
                data={data}
                propList={propList}
                idParent={idParent}
                header={t('propList.category')}
                render={(input, handleTextChange, handleFileChange) => (
                    <CategoriesForm
                        data={data}
                        input={input}
                        disableId={formOpen?.id}
                        setIdParent={setIdParent}
                        handleTextChange={handleTextChange}
                        handleFileChange={handleFileChange}
                    />
                )}
                handleClose={() => {
                    setFormOpen(false);
                }}
                // refreshPage={() => setRefresh(!refresh)}
                refreshPage={() => setCallback(prev=>!prev)}
                reqPost={classificationPost}
                reqPatch={classificationPut}
            />
        </>
    );
}
