import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Table from '../components/TableView';
import FormController from '../components/FormController';
import UsersForm from './forms/income.form';
// import UsersForm from './forms/CallsForm'
import { IncomePropList } from '../../../constants/propListConstants';
import getColumns from '../../../constants/tableColumnConstants';
import * as cookies from '../../../utils/Cookies';
import {
    incomeGet,
    incomePost,
    incomePut,
    incomeDelete,
} from '../../../utils/apiRequest';

export default function Users() {
    const lang = cookies.get('lang')
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [formOpen, setFormOpen] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [pagesToFetch, setPagesToFetch] = useState([]);
    const [waiting, setWaiting] = useState(false);
    const { t } = useTranslation();
    const [callback, setCallback] = useState(false)

    const columns = getColumns('income', t);

    useEffect(() => {
        setWaiting(true);
        const prms = [];
        for (let i of pagesToFetch) prms.push(incomeGet(i));

        Promise.all(prms)
            .then((res) => {
                const obj = [];
                res.forEach((el) => {
                    obj.push(...el.data.results);
                });
                setData([...data, ...obj]);
                setCount(res[0]?.data?.count);
            })
            .catch((err) => {
                console.error(err);
                setData([]);
            })
            .finally(() => setWaiting(false));
    }, [pagesToFetch]);
    useEffect(() => {
        setData([]);
        setPagesToFetch([1]);
    }, [refresh, callback]);

    return (
        <>
            <Table
                rows={data?.map(item=>{return{...item,vendor:item?.vendor?.name,   branch:(item?.branch !==undefined || item?.branch !== null) ? item?.branch?.name : ''}})}
                count={count}
                columns={columns}
                waiting={waiting}
                handleFormOpen={setFormOpen}
                reqDelete={incomeDelete}
                refreshPage={() => setRefresh(!refresh)}
                setPagesToFetch={setPagesToFetch}
                emptyList={data?.length === 0 && !waiting}
            />
            <FormController
                open={formOpen}
                data={data}
                disableSave={true}
                propList={IncomePropList}
                header={t('propList.user')}
                render={(input, handleTextChange, handleFileChange) => (
                    <UsersForm
                        input={input}
                        handleTextChange={handleTextChange}
                        handleFileChange={handleFileChange}
                        setRefresh={setCallback}
                        refresh={refresh}
                        fOpen={formOpen}
                        setFormOpen={setFormOpen}
                    />
                    
                )}
                handleClose={() => {
                    setFormOpen(false);
                }}
                refreshPage={() => {
                    setRefresh(!refresh)
                }}
                reqPost={incomePost}
                reqPatch={incomePut}
            />
        </>
    );
}
