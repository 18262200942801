import React, {useState} from 'react'
import Table from "./table"
import getColumns from '../../../../constants/tableColumnConstants';
import { useTranslation } from 'react-i18next';
import {postApiResponse} from "../../../../requests/getApi"
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from "../../../../components/Alert"

const TableOne = ({data, setTableThree, firstClick}) => {
    const { t } = useTranslation();
    const columns = getColumns('calculate', t);
    const [state, setState] = useState({data:null, loading:false})
    // console.log(data)
    const handleSubmit = () => {
        let items = data?.map(item=>{
            if(!item?.hasOwnProperty('calc_total')){
                return {
                    quantity:parseInt(item?.quantity),
                    good_id:item?.good?.id,
                    unit_type_id:item?.unit_type?.id,
                    price:parseInt(item?.price),
                    sum:parseInt(item?.price)?.toFixed() * parseInt(item?.quantity)?.toFixed(),
                    calc_total: parseInt(item?.price) * parseInt(item?.quantity) 
                }
            }else return{
                quantity:parseInt(item?.quantity),
                good_id:item?.good_id,
                unit_type_id:item?.unit_type?.id,
                price:parseInt(item?.price),
                sum:parseInt(item?.price) * parseInt(item?.quantity)
            }
        })
        let postData = {
            name:firstClick?.name,
            product_id:firstClick?.id,
            total_sum:data?.filter(prev=>prev?.new === true)?.reduce(function (acc, obj) { return acc + obj.calc_total; }, 0),
            items:items
        }
        postApiResponse(`/recipe/`, postData, setState)
    }
    React.useEffect(()=>{
        if(state?.success || state?.error){
            setTimeout(()=>{
                setState((prev)=>{
                    return{
                        ...prev,
                        success:false,
                        error:false
                    }
                })
            },3000)
        }
    },[state])
    return (
        <div>
            {state?.success && <Alert open={true} message="Created successfully"/>}
            {state?.error && <Alert open={true} message={state?.data?.status+" "+state?.data?.statusText} severity="error"/>}
            <div className="header_goods_pr">
                <div className="title_19sp">{t('goods_prProps.cd_list')}</div>
                {
                    data?.length>0 && firstClick &&
                    <div>
                        <LoadingButton 
                            className="btn_title_head noprvc" 
                            style={{borderRadius:40, background:'#1976d2'}}
                            onClick={handleSubmit}
                            loadingPosition="end"
                            variant="contained"
                            loading={state?.loading}
                            color="primary"
                        >
                           {t('formControl.save')}
                        </LoadingButton>
                    </div>
                }
            </div>
            <Table type="three" columns={columns}  setTableThree={setTableThree} data={data} />
        </div>
    )
}

export default TableOne