import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import Category from "../../components/product.filter"
import { Grid, TextField, Divider, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { VALUES, DARKVALUES, GREENVALUES } from '../../../../constants/styleConstants';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { categoryGoodGet } from '../../../../utils/apiRequest';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

//

export default function UsersForm(props) {
    const theme = useSelector((state) => state.theme);
    let colorValues;
    if (theme.name === 'dark') {
        colorValues = VALUES;
    } else if (theme.name === 'green') {
        colorValues = GREENVALUES;
    } else {
        colorValues = DARKVALUES;
    }
    //
    const useStyles = makeStyles((theme) => ({
        formControl: {
            margin: theme.spacing(1),
            width: '100%',
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        divider: {
            margin: '20px 0',
        },
        rightContainer: {
            display: 'flex',
            flexDirection: 'column',
        },
        sectionHeader: {
            margin: '-20px auto 10px auto',
            textAlign: 'center',
        },

        //
        colorPrimary: {
            color: colorValues.colorPrimary,
            background: colorValues.colorBgPrimary,
        },
    }));
    const classes = useStyles();
    const { t } = useTranslation();
    const [categories, setCategories] = React.useState(null);
    console.log(props.input)
    useEffect(() => { categoryGoodGet().then(res => setCategories(res.data.results)) }, [])
    return (
        <>
            <Grid container justify="space-around">
                <Grid container justify="flex-start" style={{ paddingLeft: 55 }}>
                    <Grid item sm={5}>
                        <TextField
                            fullWidth
                            required
                            variant="outlined"
                            id="name"
                            label={t('categoryProps.name')}
                            type="text"
                            value={props.input['name'] || ''}
                            onChange={props.handleTextChange('name')}
                            margin="dense"
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Divider className={clsx(classes.divider, classes.colorPrimary)} />
            <div className="title_sp19">User</div>
            <Grid container justify="space-around">
                <Grid container justify="space-around">
                    <Grid item sm={5}>
                        <TextField
                            fullWidth
                            required
                            variant="outlined"
                            id="user"
                            label={t('categoryProps.user')}
                            type="text"
                            value={props.input['user'] || ''}
                            onChange={props.handleTextChange('user')}
                            margin="dense"
                        />
                    </Grid>
                    <Grid item sm={5} className={classes.rightContainer}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            id="user_ru"
                            label={t('categoryProps.user_ru')}
                            type="text"
                            value={props.input['user_ru'] || ''}
                            onChange={props.handleTextChange('user_ru')}
                            margin="dense"
                        />
                        <TextField
                            fullWidth
                            variant="outlined"
                            id="user_uz"
                            label={t('categoryProps.user_uz')}
                            type="text"
                            value={props.input['user_uz'] || ''}
                            onChange={props.handleTextChange('user_uz')}
                            margin="dense"
                        />
                        <TextField
                            fullWidth
                            variant="outlined"
                            id="user_en"
                            label={t('categoryProps.user_en')}
                            type="text"
                            value={props.input['user_en'] || ''}
                            onChange={props.handleTextChange('user_en')}
                            margin="dense"
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Divider className={clsx(classes.divider, classes.colorPrimary)} />
            <div className="title_sp19">Branch</div>
            <Grid container justify="space-around">
                <Grid item sm={5}>
                    <TextField
                        fullWidth
                        required
                        variant="outlined"
                        id="name"
                        label={t('categoryProps.description')}
                        type="text"
                        value={props.input['description'] || ''}
                        onChange={props.handleTextChange('description')}
                        margin="dense"
                    />
                </Grid>
                <Grid item sm={5} className={classes.rightContainer}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        id="name_ru"
                        label={t('categoryProps.description_ru')}
                        type="text"
                        value={props.input['description_ru'] || ''}
                        onChange={props.handleTextChange('description_ru')}
                        margin="dense"
                    />
                    <TextField
                        fullWidth
                        variant="outlined"
                        id="name_uz"
                        label={t('categoryProps.description_uz')}
                        type="text"
                        value={props.input['description_uz'] || ''}
                        onChange={props.handleTextChange('description_uz')}
                        margin="dense"
                    />
                    <TextField
                        fullWidth
                        variant="outlined"
                        id="name_en"
                        label={t('categoryProps.description_en')}
                        type="text"
                        value={props.input['description_en'] || ''}
                        onChange={props.handleTextChange('description_en')}
                        margin="dense"
                    />
                </Grid>
            </Grid>
            <Divider className={clsx(classes.divider, classes.colorPrimary)} />

            <Grid container justify="space-around">
                <Grid item sm={5}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        id="name_uz"
                        label={t('categoryProps.description_uz')}
                        type="text"
                        value={props.input['description_uz'] || ''}
                        onChange={props.handleTextChange('description_uz')}
                        margin="dense"
                    />
                </Grid>
                <Grid item sm={5}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        id="name_uz"
                        label={t('categoryProps.description_uz')}
                        type="text"
                        value={props.input['description_uz'] || ''}
                        onChange={props.handleTextChange('description_uz')}
                        margin="dense"
                    />
                </Grid>
            </Grid>
        </>
    );
}
