import React,{useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import {Wrapper,Container} from "./style"
import {
    TextField,
    makeStyles,
} from '@material-ui/core';
import {getResponse} from "../../../../../requests/getApi"
import SendIcon from '@mui/icons-material/Send';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import LoadingButton from '@mui/lab/LoadingButton';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {postResponse, putResponse} from "../../../../../requests/getApi"
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useSelector } from 'react-redux';
import {VALUES, DARKVALUES, GREENVALUES} from '../../../../../constants/styleConstants';
import randomColor from "randomcolor";

export default function UsersForm(props) {
    const theme = useSelector((state) => state.theme);
    let colorValues;
    if (theme.name === 'dark') {
        colorValues = VALUES;
    } else if (theme.name === 'green') {
        colorValues = GREENVALUES;
    } else {
        colorValues = DARKVALUES;
    }
    //
const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        width: '100%',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    divider: {
        margin: '20px 0',
    },
    rightContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    sectionHeader: {
        margin: '-20px auto 10px auto',
        textAlign: 'center',
    },

    //
    colorPrimary: {
        color: colorValues.colorPrimary,
        background: colorValues.colorBgPrimary,
    },
}));
    const classes = useStyles();
    const {open, setFormOpen, refreshPage} = props;
    const { t } = useTranslation();
    const [post, setPost] = useState({success:'', error:''})
    const [state, setState] = useState({title_uz:'', title_en:'', title_ru:'', answers:[]})
    const [answers, setAnswers] = useState([]) 
    const [answerItem, setAnswerItem] = useState({uz:'', ru:'', en:''})
    const [error, setError] = useState(false)
    const [load, setLoad] = useState(false)
    const [getById, setGetById] = useState({error:'',success:''})
    const [errorQ, setErrorQ] = useState({uz:false, ru:false, en:false})

    const handleTextChange = (e) =>{
        if(e.target.value !== '') setError(false)
        setAnswerItem({...answerItem, [e.target.name]:e.target.value})
    }
    const handleClick = () =>{
        let colourChange = randomColor()
        if(answerItem.uz !== '' || answerItem.ru !== '' || answerItem.en !== ''){
            setAnswers([...answers, {...answerItem, colour:colourChange}])
            setAnswerItem({uz:'',en:'',ru:''})
        }else{
            setError(true)
        }
    }
    function handleDelete(item){
        let newList = answers.filter((prev=>prev !== item))
        setAnswers(newList)
    }
    
    useEffect(()=>{if(open?.id) getResponse(`/questions/${props?.open?.id}/`, setGetById)},[open])
    useEffect(()=>{
        if(getById?.success!==''){
            setState(getById?.success?.data)
            let answersList = getById?.success?.data?.answers?.map( ( item)=>{
                return{
                    uz:item.title_uz,
                    ru:item.title_ru,
                    en:item.title_en,
                    colour:randomColor()
                }
            })
            setAnswers(answersList)

        }
    },[getById])
    useEffect(() =>{setState({...state, answers:answers})},[answers])

    useEffect(() =>{
        if(state?.title_uz !=='') setErrorQ({...errorQ, uz:false})
        if(state?.title_en !=='') setErrorQ({...errorQ, en:false})
        if(state?.title_ru !=='') setErrorQ({...errorQ, ru:false})
    },[state])
    
    const handleSubmit = () =>{
        if(state?.title_uz ==='') return setErrorQ({...errorQ, uz:true})
        if(state?.title_en ==='') return setErrorQ({...errorQ, en:true})
        if(state?.title_ru ==='') return setErrorQ({...errorQ, ru:true})
        setLoad(true)
        if(open?.id){
            putResponse(`/questions/${open?.id}/`,state, setPost)
        }else
            postResponse('/questions/',state, setPost)
    }
    useEffect(() =>{
        if(post?.success !==""){
            setLoad(false)
            setFormOpen(false)
            return refreshPage()
        }
    },[post])
    return (
        <Wrapper>
            <Container>
                <TextField
                    multiline
                    error={errorQ.uz}
                    className="text_area"
                    variant="outlined"
                    id="phone_number"
                    margin="dense"
                    placeholder="Savol kiriting..."
                    minRows={3}
                    value={state.title_uz}
                    onChange={(e)=>setState({...state, title_uz:e.target.value})}
                />
                <TextField
                    multiline
                    error={errorQ.en}
                    className="text_area"
                    variant="outlined"
                    id="phone_number"
                    margin="dense"
                    placeholder="Write a question..."
                    minRows={3}
                    value={state.title_en}
                    onChange={(e)=>setState({...state, title_en:e.target.value})}
                />
                <TextField
                    multiline
                    error={errorQ.ru}
                    className="text_area"
                    variant="outlined"
                    id="phone_number"
                    margin="dense"
                    placeholder="Написать вопрос..."
                    minRows={3}
                    value={state.title_ru}
                    onChange={(e)=>setState({...state, title_ru:e.target.value})}
                />
                {/* <hr/> */}
                <div className="answer">Javob variantlarini kiriting...</div>
                <div>
                    {
                        answers.length>0 ? 
                        answers.map((item,index) => (
                            <div key={index} className="list_answer">
                                <FiberManualRecordIcon style={{color:item.colour, marginRight:4}}/>
                                <div style={{display: 'flex', flexDirection: 'column' }}>
                                    <div className="list_answer_item">{item.uz}</div>
                                    <div className="list_answer_item">{item.en}</div>
                                    <div className="list_answer_item">{item.ru}</div>
                                </div>
                                <HighlightOffIcon onClick={()=>handleDelete(item)} className="del_icon" style={{color:'#f76666', fontSize:'20px', cursor:'pointer'}}/>
                            </div>)
                        ):null
                    }
                </div>
                <div className="add_text">
                    <div className="add_text_cont" style={{display:'flex', width:'100%', flexDirection:'column'}}>
                        <TextField
                            error={error}
                            fullWidth
                            required
                            name="uz"
                            value={answerItem.uz}
                            onChange={handleTextChange}
                            id="phone_number"
                            label={<span style={{position: 'relative', top: '-4px'}}>Javobni kiriting..</span>}
                            margin="dense"
                        />
                        <TextField
                            error={error}
                            fullWidth
                            required
                            name="en"
                            value={answerItem.en}
                            onChange={handleTextChange}
                            id="phone_number"
                            label={<span style={{position: 'relative', top: '-4px'}}>Write the answer..</span>}
                            margin="dense"
                        />
                        <TextField
                            error={error}
                            fullWidth
                            required
                            name="ru"
                            value={answerItem.ru}
                            onChange={handleTextChange}
                            id="phone_number"
                            label={<span style={{position: 'relative', top: '-4px'}}>Напишите ответ..</span>}
                            margin="dense"
                        />
                    </div>
                    <AddCircleIcon onClick={handleClick} className="icon_add" color="primary" />
                </div>
                <div style={{padding:"10px 0 10px 10px", marginTop:20, width:'100%', textAlign: 'right'}}>
                    <LoadingButton
                        onClick={handleSubmit}
                        endIcon={post?.success !=='' ? <CheckCircleOutlineIcon/> : (post?.error !=='' ? <ErrorOutlineIcon/> : <SendIcon />)}
                        loading={load}
                        loadingPosition="end"
                        variant="contained"
                    >
                        {
                            (post?.success === '' && post?.error === '') ? "Jo'natish":
                            ((post?.success !=='' && post?.error == '') ? "Jo'natildi":"Jo'natilmadi") 
                        }
                    </LoadingButton>
                </div>
            </Container>
        </Wrapper>
    );
}
