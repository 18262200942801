import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';
import {useDispatch} from "react-redux"
import { Button, Menu, MenuItem, makeStyles, Hidden } from '@material-ui/core';
import { Language as TranslateIcon } from '@material-ui/icons';

import { setLang as setLangCookie } from '../../../utils/Cookies';
import langList from '../../../language/langList';
import {VALUES, DARKVALUES, GREENVALUES} from '../../../constants/styleConstants';
import {useSelector} from "react-redux";
import {changeLang} from "../../../redux/actions/lang.action"

function LangSwitcher(props) {
    const dispatch = useDispatch()
    const [lang, setLang] = useState();
    const [anchorEl, setAnchorEl] = useState(null);
    const theme = useSelector(state => state.theme)
    let colorValues;
    if (theme.name === 'dark') {
        colorValues = VALUES;
    } else if (theme.name === 'green') {
        colorValues = GREENVALUES;
    } else {
        colorValues = DARKVALUES;
    }
    const useStyles = makeStyles((theme) => ({
        root: {
            marginRight: '15px',
            [theme.breakpoints.down('xs')]: {
                width: '30px !important',
            },
        },
        button: {
            padding: '4px',
        },
    
        //
        colorPrimary: {
            color: colorValues.colorPrimary,
            colorBgPrimary: colorValues.colorBgPrimary,
        },
        colorMenu:{
            color: colorValues.colorMenu,
        }
    }));

    const classes = useStyles();
    const objLang = {
        Русский:'ru',
        ["O'zbek tili"]:'uz',
        English:'en'
    }
    useEffect(() => {
        const { label } = langList.filter((el) => el.value === props.i18n.language.slice(0, 2))[0];
        setLang(label);
        dispatch(changeLang(objLang[label]))
    }, [props.i18n.language]);

    function handleClick(event) {
        setAnchorEl(event.currentTarget);
    }
    function handleClose() {
        setAnchorEl(null);
    }
    function handleChange(element) {
        // console.log(element.value)
        return function () {
            props.i18n.changeLanguage(element.value);
            setLangCookie(element.value);
            // dispatch(changeLang(element));
            handleClose();
        };
    }

    return (
        <div className={classes.root}>
            <Button
                className={clsx(classes.button, classes.colorPrimary)}
                aria-haspopup="true"
                startIcon={<TranslateIcon />}
                onClick={handleClick}
            >
                <Hidden xsDown>{lang}</Hidden>
            </Button>

            <Menu
                id="lang-switcher-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {langList.map((el) => (
                    <MenuItem key={el.value} className={classes.colorMenu} onClick={handleChange(el)}>
                        {el.label}
                        
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}

export default withTranslation()(LangSwitcher);
