import { useEffect } from 'react';
import * as cookies from '../../utils/Cookies';

export default function Logout() {
    useEffect(() => {
        cookies.logout();
        setTimeout(() => {
            window.location = '/login';
        }, 200);
    });

    return null;
}